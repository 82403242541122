import React, { useEffect, useState } from "react";

import "./index.scss";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { MDBInput } from "mdb-react-ui-kit";
import Button from "../../components/generic/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { ErrorMessage, Form, Formik } from "formik";
import useUserData from "../../hooks/useUserData";
// @ts-ignore
import Cookies from "js-cookie";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import useLogout from "../../hooks/useLogout";
import getCSRFToken from "../../stores/CSRFStore";
import { Dialog, Box, Stack } from "@mui/material";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

interface Customer {
  email: string;
  name: string | null;
  phone?: string;
}

interface CustomerAddress {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
}

interface PaymentMethod {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  owner: string;
}

interface CustomerData {
  customer: Customer;
  customer_address?: CustomerAddress;
  payment_methods: PaymentMethod;
}

const Profile = () => {
  const axios = useAxiosPrivate();

  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  useEffect(() => {
    axios.get("payment-api/customer-data").then((response) => {
      setCustomerData(response.data);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div className={"payments__container"}>
      <h2 className="heading__h2" style={{
        marginBottom: 0
      }}>Twoje konto</h2>
      {/* <UserStatsBox /> */}
      <div className="account__row">
        <UserEmailBox />
        {/*<ChangePasswordBox />*/}
      </div>
      {customerData && <UserDataBox customerData={customerData} />}
      <NotificationsPreferences />
      <UserSettings />
      {/*<DeleteAccountBox />*/}
      <br />
    </div>

  );
};

interface UserEmailBoxProps {
  email: string | null;
}

interface UserDataBoxProps {
  customerData: CustomerData;
}

const UserDataBox: React.FC<UserDataBoxProps> = ({ customerData }) => {
  const axios = useAxiosPrivate();

  const generateCustomerPortalSession = () => {
    axios.get("payment-api/create-customer-portal-session").then((response) => {
      if(response.data && response.data.href){
        document.location.href = response.data.href;
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className="payments__box">
      <h3 className="heading__h3" style={{margin: 0}}>Dane do faktury</h3>
      <p className="payments__header-p">Te dane wykorzystwane są do rozliczeń, wystawiania faktur itp.</p>
      <br/>

      {customerData.customer &&
      <div className="account__userdata__container">
        <div className="account__userdata__item">
          <p className={"payments__header-p"}>Nazwa firmy / Imię i&nbsp;nazwisko</p>
          <p>{customerData.customer.name ? customerData.customer.name : "-"}</p>
        </div>
        <div className="account__userdata__item">
          <p className={"payments__header-p"}>Email</p>
          <p>{customerData.customer.email ? customerData.customer.email : "-"}</p>
        </div>
        <div className="account__userdata__item">
          <p className={"payments__header-p"}>Numer telefonu</p>
          <p>{customerData.customer.phone ? customerData.customer.phone : "-"}</p>
        </div>
        {customerData.customer_address != null &&
          <>
        <div className="account__userdata__item">
          <p className={"payments__header-p"}>Adres</p>
          <p>{customerData.customer_address.line1 ? customerData.customer_address.line1 : "-"} {customerData.customer_address.line2 && customerData.customer_address.line2}</p>
        </div>
        <div className="account__userdata__item">
          <p className={"payments__header-p"}>Miasto</p>
          <p>{customerData.customer_address.city ? customerData.customer_address.city : "-"}</p>
        </div>
        <div className="account__userdata__item">
          <p className={"payments__header-p"}>Kod pocztowy</p>
          <p>{customerData.customer_address.postal_code ? customerData.customer_address.postal_code : "-"}</p>
        </div>
          </>}
      </div>}


      {customerData.payment_methods && customerData.payment_methods.brand && <div>
        <h3 className="heading__h3">Metoda płatności</h3>

        <div className="account__userdata__card">

          <div className="account__userdata__card__item" style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 24,
            alignItems: "center"
          }}>
            <span className="account__userdata__card__type">{customerData.payment_methods.brand}</span>
            <div>
              <p className={"payments__header-p"}>Numer karty</p>
              <p>**** **** **** {customerData.payment_methods.last4}</p>
            </div>

          </div>
          <div className="account__userdata__card__item">
            <p className={"payments__header-p"}>Właściciel</p>
            <p>{customerData.payment_methods.owner}</p>
          </div>
          <div className="account__userdata__card__item">
            <p className={"payments__header-p"}>Data ważności</p>
            <p>{customerData.payment_methods.exp_month + "/" + customerData.payment_methods.exp_year}</p>
          </div>

        </div>
      </div>}


      <div style={{
        display: "flex",
        justifyContent: "space-between",
        gap: 12,
        flexWrap: "wrap",
        alignItems: "center"
      }}>
        <IntroduceYourselfDialogButton />
        <Button
          id="reset-password-button"
          variant="contained-dark"
          type="submit"

          onClick={generateCustomerPortalSession}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8
            }}>
            <p style={{ margin: 0 }}>Zaktualizuj dane</p>
            <svg xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512" style={{
              height: 12
            }}>
              <path
                fill={"#fff"}
                d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
            </svg>
          </div>

        </Button>
      </div>
    </div>
  )
}

const UserStatsBox = () => {
  return (
    <div className="payments__box" style={{ paddingTop: 18 }}>
      <h3 className="heading__h3">Statystyki</h3>

      <div className="account__stats__container">
        <StatBox
          icon={<DocumentScannerIcon />}
          heading={">12572"}
          desc={"Tyle dokumentów Gaius przeczytał, żeby udzielić Ci odpowiedzi"}
        />
        <StatBox
          icon={<DocumentScannerIcon />}
          heading={"578 minut"}
          desc={"Tyle średnio czasu zaoszczędziłeś na analizie dokumentów"}
         />
        <StatBox
          icon={<DocumentScannerIcon />}
          heading={"14"}
          desc={"Napisanych dokumentów"}
         />
        <StatBox
          icon={<DocumentScannerIcon />}
          heading={"252"}
          desc={"Udzielonych odpowiedzi"}
         />
      </div>
    </div>
  )
}

const StatBox = ({ icon, heading, desc }: { icon: React.ReactNode, heading: string, desc: string }) => {
  return (
    <div className="account__stats__box">
      <div className="account__stats__box__icon">
        {icon}
      </div>
      <div className="account__stats__box__content">
        <p className="account__stats__box__heading">{heading}</p>
        <p className="account__stats__box__desc">{desc}</p>
      </div>
    </div>
  )
}

const UserEmailBox = () => {
  const { userData } = useUserData();

  return (
    <div className="payments__box" style={{ margin: "0 0 auto" }}>
      <h3 className="heading__h3" style={{margin: 0}}>Twój adres email i&nbsp;numer telefonu</h3>
      <p className="payments__header-p" style={{marginBottom: 24}}>To dane na które zarejestorwane jest Twoje konto.</p>

      <MDBInput
        id="email-input"
        name="email"
        label="Twój adres email"
        disabled={true}
        value={userData.email}
        type="email"
      /><br />
      <MDBInput
        id="phone-input"
        name="phone"
        label="Numer telefonu"
        disabled={true}
        value={userData.phone}
        type="tel"
      />
    </div>
  );
};

const ChangePasswordBox = () => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (values: { currentPassword: string, newPassword: string, newPassword2: string }, _actions: {}) => {

  };

  return (
    <div className="payments__box">
      <h3 className="heading__h3">Zmień hasło</h3>
      <p className="payments__header-p">&nbsp;</p>
      <form action="">
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            newPassword2: ""
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <div>
                  <ErrorMessage
                    name="email"
                    component={(props: any) => (
                      <div>
                        <div style={{ float: "left" }}>
                          {props?.children}
                        </div>
                        <div style={{ float: "right" }}>X</div>
                      </div>
                    )}
                  />
                </div>
                <MDBInput
                  className="mt-2"
                  id="current-password"
                  name="current-password"
                  autoComplete={"current-password"}
                  label="Aktualne hasło"
                  type="password"
                  value={formik.values?.currentPassword}
                  onChange={formik.handleChange}
                />
                <br />
                <MDBInput
                  className="mt-2"
                  id="new-password"
                  name="new-password"
                  autoComplete={"new-password"}
                  label="Nowe hasło"
                  type="password"
                  value={formik.values?.newPassword}
                  onChange={formik.handleChange}
                />
                <br />
                <MDBInput
                  className="mt-2"
                  id="new-password2"
                  name="new-password2"
                  autoComplete={"new-password"}
                  label="Powtórz nowe hasło"
                  type="password"
                  value={formik.values?.newPassword2}
                  onChange={formik.handleChange}
                />
                <br />
                <div style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}>
                  <Button
                    id="reset-password-button"
                    variant="contained-dark"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress /> : "Zmień hasło"}
                  </Button>
                </div>
              </Form>
          );
          }}
          </Formik>
          </form>
          </div>
          )
            ;
          };

const NotificationsPreferences = () => {
  const storedConsent = Cookies.get("consent");
  const consent = storedConsent ? JSON.parse(storedConsent) : {};
  const [consents, setConsents] = React.useState({
    necessary: consent.necessary !== undefined ? consent.necessary : true,
    preferences: consent.preferences || false,
    statistics: consent.statistics || false,
    marketing: consent.marketing || false
  });

  const snackBar = React.useContext(SnackbarContext);

  const savePreferences = () => {
    Cookies.set("consent", JSON.stringify(consents), { expires: 1095 });
    snackBar.setMessage("Zaktualizowano preferencje dotyczące ciasteczek");
    snackBar.setSeverity("success");
    snackBar.setOpen(true);
  }

  return (
    <div className="payments__box">
      {/*<h3 className="heading__h3">Preferencje powiadomień</h3>*/}
      {/*<p className="payments__header-p">Przesyłamy tylko naprawdę ważne i potrzebne materiały branżowe.</p>*/}

      {/*<div className="account__preference__item__container">*/}
      {/*  <div className="account__preference__item">*/}
      {/*    <p className="account__preference__item-text">Newsletter prawniczy</p>*/}
      {/*    <input type="checkbox" name="" id="" />*/}
      {/*  </div>*/}
      {/*  <div className="account__preference__item">*/}
      {/*    <div>*/}
      {/*      <p className="account__preference__item-text">Aktualizacje w Gaius-Lex</p>*/}
      {/*      <p className="account__preference__item-desc">Cotygodniowy mail podsumowujący oraz zapowiadający zmiany w naszej aplikacji.</p>*/}
      {/*    </div>*/}
      {/*    <input type="checkbox" name="" id="" />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <h3 className="heading__h3">Ciasteczka</h3>
      <p className="payments__header-p">Serwis Internetowy wykorzystuje następujące pliki cookie:</p>
      <form onSubmit={savePreferences} className="account__preference__item__container">
        <label form="consent_necessary" className="account__preference__item disabled">
          <div className={"account__preference__item__content"}>
            <p className="account__preference__item-text">Niezbędne*</p>
            <p className="account__preference__item-desc">czyli pliki cookie wymagane do minimalnej obsługi Serwisu
              Internetowego, w celu jego prawidłowego wyświetlania;</p>
          </div>
          <div
            className={"account__preference__checkbox active"} style={{filter: "grayscale(1)"}}></div>
          <input type="checkbox" style={{ display: "none" }} id="consent_necessary" disabled
                 checked={consents.necessary} />
        </label>
        <label form="consent_statistics" className="account__preference__item">
          <div className={"account__preference__item__content"}>
            <p className="account__preference__item-text">Statystyczne</p>
            <p className="account__preference__item-desc">czyli pliki cookie, umożliwiające zbieranie informacji o
              sposobie korzystania przez Państwa z Serwisu Internetowego i badanie Państwa działań podejmowanych w
              ramach Serwisu Internetowego;</p>
          </div>
          <div
            className={consents.statistics ? "account__preference__checkbox active" : "account__preference__checkbox"}></div>
          <input
            type="checkbox"
            id="consent_statistics"
            checked={consents.statistics}
            style={{display: "none"}}
            onChange={(e) => {
              setConsents({
                ...consents,
                statistics: e.target.checked
              });
            }}
          />
        </label>
        <label form="preferences" className="account__preference__item">
          <div className={"account__preference__item__content"}>
            <p className="account__preference__item-text">Funkcjonalne</p>
            <p className="account__preference__item-desc">czyli pliki cookie, które umożliwiają „zapamiętanie” wybranych przez Państwa ustawień i personalizację interfejsu, np. w zakresie wybranego języka, rozmiaru czcionki, wyglądu strony Strony Internetowej;</p>
          </div>
          <div className={consents.preferences ? "account__preference__checkbox active" : "account__preference__checkbox"}></div>
          <input
            type="checkbox"
            id="consent_preferences"
            checked={consents.preferences}
            style={{display: "none"}}
            onChange={(e) => {
              setConsents({
                ...consents,
                preferences: e.target.checked
              });
            }}
          />
        </label>
        <label form="marketing" className="account__preference__item">
          <div className={"account__preference__item__content"}>
            <p className="account__preference__item-text">Marketingowe</p>
            <p className="account__preference__item-desc">czyli pliki cookie sesyjne; Administrator przechowuje te dane
              do czasu cofnięcia wyrażonej przez Państwa zgody. Dane te są przetwarzane do czasu usunięcia danych lub do
              czasu cofnięcia zgody, które nie ma wpływu na przetwarzanie, którego dokonano przed cofnięciem zgody.</p>
          </div>
          <div
            className={consents.marketing ? "account__preference__checkbox active" : "account__preference__checkbox"}></div>
          <input
            type="checkbox"
            id="consent_marketing"
            style={{display: "none"}}
            checked={consents.marketing}
            onChange={(e) => {
              setConsents({
                ...consents,
                marketing: e.target.checked
              });
            }}
          />
        </label>
      </form>
      <div style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <Button
          id="reset-password-button"
          variant="contained-dark"
          type="submit"
          onClick={savePreferences}
        >
          Zapisz zmiany
        </Button>
      </div>
    </div>
  );
};

const UserSettings = () => {
  const axios = useAxiosPrivate();
  const { userData } = useUserData();
  const [settings, setSettings] = React.useState({
    default_anonymization: userData?.defaultAnonymization,
    disable_anonymization: userData?.disableAnonymization,
  });
  const snackBar = React.useContext(SnackbarContext);

  React.useEffect(() => {
    getCSRFToken();
  }, [])

  const savePreferences = () => {

    const data = {
      default_anonymization: settings.default_anonymization,
      disable_anonymization: settings.disable_anonymization,
    };

    axios.post(
      "anonymizer/api/v1/anonymizer-settings",
      JSON.stringify(data),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      snackBar.setMessage("Zaktualizowano ustawienia");
      snackBar.setSeverity("success");
      snackBar.setOpen(true);
    }).catch((_error) => {
      snackBar.setMessage("Wystąpił błąd podczas zapisywanie ustawień. Spróbuj ponownie");
      snackBar.setSeverity("error");
      snackBar.setOpen(true);
    });
  };

  return (
    <div className="payments__box">
      <h3 className="heading__h3">Ustawienia</h3>
      <form
        onSubmit={savePreferences}
        className="account__preference__item__container"
      >
        <label form="consent_necessary" className="account__preference__item">
          <div className={"account__preference__item__content"}>
            <p className="account__preference__item-text">
              Domyślna anonimizacja
            </p>
            <p className="account__preference__item-desc">
              Pliki dodawane do systemu za pomocą opcji "Własna baza" będą
              automatycznie anonimizowane, BEZ możliwości edytowania procesu
              anonimizacji.
            </p>
          </div>
          <div
            className={
              settings.default_anonymization
                ? "account__preference__checkbox active"
                : "account__preference__checkbox"
            }
          ></div>
          <input
            type="checkbox"
            id="default_anonymization"
            checked={settings.default_anonymization}
            style={{ display: "none" }}
            onChange={(e) => {
              setSettings({
                ...settings,
                default_anonymization: e.target.checked,
              });
            }}
          />
        </label>
      </form>
      <form
        onSubmit={savePreferences}
        className="account__preference__item__container"
      >
        <label form="consent_necessary" className="account__preference__item">
          <div className={"account__preference__item__content"}>
            <p className="account__preference__item-text">
              Wyłącz anonimizację
            </p>
            <p className="account__preference__item-desc">
              Pliki dodawane do systemu za pomocą opcji "Własna baza" NIE będą
              poddawane anonimizacji.
            </p>
          </div>
          <div
            className={
              settings.disable_anonymization
                ? "account__preference__checkbox active"
                : "account__preference__checkbox"
            }
          ></div>
          <input
            type="checkbox"
            id="disable_anonymization"
            checked={settings.disable_anonymization}
            style={{ display: "none" }}
            onChange={(e) => {
              setSettings({
                ...settings,
                disable_anonymization: e.target.checked,
              });
            }}
          />
        </label>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          id="reset-password-button"
          variant="contained-dark"
          type="submit"
          onClick={savePreferences}
        >
          Zapisz zmiany
        </Button>
      </div>
    </div>
  );
};


const DeleteAccountBox = () => {
  const axios = useAxiosPrivate();

  const snackBar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    password2: ""
  })

  const DeleteUserHandle = () => {
    axios.post("core_api/user/delete", formData).then((response) => {

      if(response.status == 200){
        snackBar.setMessage("Poprawnie usunięto konto");
        snackBar.setSeverity("success");
        snackBar.setOpen(true);
        useLogout();
      } else {
        snackBar.setMessage("Wystąpił błąd podczas usuwania konta");
        snackBar.setSeverity("error");
        snackBar.setOpen(true);
      }
    }).catch((error) => {
      console.error(error);
    });
  }


  return (
    <div className="payments__box">
      <h3 className="heading__h3">Usuń konto</h3>
      <p className="payments__header-p">Usuwasz całkowicie konto. Czy jesteś pewien, że chcesz wykonać tą operację?</p>

      <div className="account__alert">Utracisz całą historię swoich postępów. Nie będziesz mógł przywrócić zmian.</div>

      <div style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <Button
          id="reset-password-button"
          variant="contained-dark"
          type="submit"
          onClick={() => {setModalOpen(true)}}
        >
          Tak, usuń moje konto
        </Button>
      </div>
      <div className={modalOpen ? "account__modal active" : "account__modal"}>
        <div className="account__modal__content">
          <div>
            <h3 className="heading__h3">Potwierdź usunięcie konta</h3>
            <p className="payments__header-p">Aby usunąć konto, wpisz swoje hasło i potwierdź.</p>
          </div>
          <MDBInput
            id="password-input"
            name="password"
            label="Twoje hasło"
            type="password"
            value={formData.password}
            onChange={(e) => {
              setFormData({
                ...formData,
                password: e.target.value
              });
            }}
          />
          <MDBInput
            id="password2-input"
            name="password2"
            label="Powtórz hasło"
            type="password"
            value={formData.password2}
            onChange={(e) => {
              setFormData({
                ...formData,
                password2: e.target.value
              });
            }}
          />
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            flexWrap: "wrap"
          }}>
            <Button
              id="cancel-delete-account-button"
              variant="contained-dark"
              type="submit"
              onClick={() => {setModalOpen(false)}}
            >
              Anuluj
            </Button>
            <Button
              id="delete-account-button"
              type="submit"
              onClick={DeleteUserHandle}
            >
              Usuń konto
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

const IntroduceYourselfDialogButton = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>("");
  
  const snackbarContext = React.useContext(SnackbarContext);
  const axios = useAxiosPrivate();

  const saveIntroduction = async (introduction: string) => {

    await getCSRFToken();
    axios.post("/api/v1/introduction", JSON.stringify({ introduction }), {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        snackbarContext.setSeverity("success");
        snackbarContext.setMessage("Zapisano dane użytkownika");
        snackbarContext.setOpen(true);
      })
      .catch((error) => {
        snackbarContext.setSeverity("error");
        snackbarContext.setMessage("Nie udało się zapisać danych użytkownika");
        snackbarContext.setOpen(true);
        console.error(error);
      });
  }

  React.useEffect(() => {
    axios.get("/api/v1/introduction").then((response) => {
      setValue(response.data.text);
    });
  }, []);

  return (
    <div className={"chat__introduce__container"}>
      <Button sx={{ width: "fitContent" }} variant="outlined-light" onClick={() => setOpen(true)}>Przedstaw się Gaiusowi</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2 }} className="d-flex flex-column" justifyContent={"center"}>
          <h3 className={"heading__h3"}>Cześć! Jestem...</h3>
          <p>Podaj dane, których Gaius powinien używać podczas tworzenia umów.</p>
          <Stack spacing={2} direction="column" justifyContent="center" className="w-100">
            <textarea
              rows={5}
              className={"chat__introduce__textarea"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={`Jan Kowalski
Kancelaria Adwokacka Jan Kowalski
ul. Wspólna 12
00-950 Warszawa
Polska

NIP: 123-456-78-90
REGON: 987654321

+48 22 123 45 67
jan.kowalski@kancelaria.pl
www.kancelariajkowalski.pl`}
            />
            <Button onClick={() => {
              setOpen(false);
              saveIntroduction(value);
            }} variant="contained-dark">Przedstaw się</Button>
          </Stack>
        </Box>
      </Dialog>
    </div>
  )
}