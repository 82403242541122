import React from "react";
import { Autocomplete, Box, Grid, Stack, TextField, Tooltip, Typography, Dialog, Modal } from "@mui/material";
import Button from "../../components/generic/Button";
import { Message, MessageProps } from "../caseLawSearch/Message";
import useGaiusWebSocket from "../../hooks/useGaiusWebSocket";
import { Formik } from "formik";
import { Helmet } from "react-helmet";
import LinearDeterminate from "../../components/LinearBuffer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import { Link } from "react-router-dom";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import "./index.scss";
import useAutosizeTextArea from "./useAutosizeTextArea";
import ChatSources from "./ChatSources";
const GaiusLexLogo = require("~/public/static/img/gaius-lex-logo.svg");
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router";


const dissatisfied = require("~/public/static/img/dissatisfied.svg");
const satisfied = require("~/public/static/img/satisfied.svg");
const search = require("~/public/static/img/sprawa_karna.webp");
const pearls = require("~/public/static/img/pearls.webp");
const cars = require("~/public/static/img/cars.png");



const SelectDocumentButton = ({ callback, limit, orientation, initDocuments }: { callback: any, limit?: number, orientation?: "horizontal" | "vertical", initDocuments?: string[] }) => {
  const LIMIT = limit || 70000;
  const orient = orientation || "horizontal";

  const [previousDocument, setPreviousDocument] = React.useState<any>(null);
  const [documents, setDocuments] = React.useState<any>([]);
  const [chosenDocumentPath, setChosenDocumentPath] = React.useState<any>(null);
  const [currentLength, setCurrentLength] = React.useState<number>(0);

  const axios = useAxiosPrivate();

  const getDocuments = async () => {
    const response = await axios.get(`/api/v1/list-documents?path=test-collection`)

    setDocuments(response.data);

    return response.data;
  }

  const getDocumentContent = async (path: Array<any>) => {
    const documents = [];

    for (let i = 0; i < path.length; i++) {
      const name = path[i].path.split("/").pop();
      const response = await axios.get(`/api/v1/document?page=0&path=${path[i].path}`);
      documents.push({
        fullName: path[i].path,
        name: name,
        content: response.data.content
      });
    }

    callback(documents);
    setPreviousDocument(path);
  }

  React.useEffect(() => {
    getDocuments().then((docs) => {
      if (initDocuments && !previousDocument) {
        const transfromedInitDocuments = initDocuments.map(path => docs.find((el: {path: string}) => el.path === path))
        setChosenDocumentPath(transfromedInitDocuments);
        setCurrentLength(transfromedInitDocuments.reduce((acc: number, val: any) => acc + val.length, 0));
        getDocumentContent(transfromedInitDocuments);
      }
    })

  }, [initDocuments]);

  const displayAutocomplete = initDocuments ? chosenDocumentPath: true;
  return (
    <div className={"chat__documents"}>
      <Stack direction={orient === "horizontal" ? "row" : "column"} spacing={2} className="w-100" justifyContent={orient === "horizontal" ? "end" : "center"}>
        { displayAutocomplete && <Autocomplete
          id="combo-box-demo"
          disablePortal
          onChange={(event, newValue: any) => {
            setChosenDocumentPath(newValue);
            setCurrentLength(newValue.reduce((acc: number, val: any) => acc + val.length, 0));
          }}
          getOptionDisabled={(option) => (option as any).length > LIMIT - currentLength || (option as any).length === 0}
          options={documents?.filter ? documents?.filter((document: { private: boolean }) => !document.private) : documents}
          getOptionLabel={(option) => (option as any).path?.split("/").pop()}
          style={{ width: "100%", maxWidth: "400px" }}
          renderInput={(params) => <TextField {...params} label={`Wybierz dokumenty (${currentLength}/${LIMIT})`} />}
          defaultValue={initDocuments ? chosenDocumentPath: undefined}
          multiple
        />}
        <Button
          disabled={!chosenDocumentPath || chosenDocumentPath === previousDocument || LIMIT - currentLength < 0}
          variant={"contained-dark"}
          onClick={() => {
            if (chosenDocumentPath) {
              getDocumentContent(chosenDocumentPath);
            }
          }}>
          {chosenDocumentPath === previousDocument ? "Dokumenty już dodane" : "Dodaj dokumenty"}
        </Button>
      </Stack>
    </div>
  );
}

interface SelectedSources extends Array<string> {}

interface SourceGroup {
  [key: string]: {
    label: string;
    sources: {
      [key: string]: {
        value: string;
        disabled: boolean;
      };
    };
  }
}

const ChatWithGPT = () => {
  const location = useLocation();
  const initLogId = location.state?.logId;
  const [logId, setLogId] = React.useState<string | undefined>(initLogId);
  const initSourcesId = location.state?.sourcesId;

  const [conversation, setConversation] = React.useState<MessageProps[]>([]);
  const [lastDocuments, setLastDocuments] = React.useState<Array<any> | undefined>();
  const [newestMessage, setNewestMessage] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<any>([]);
  const [executing, setExecuting] = React.useState<boolean>(false);
  const [referredDocument, setReferredDocument] = React.useState<Array<any>>([]);
  const snackbarContext = React.useContext(SnackbarContext);
  const [introduction, setIntroduction] = React.useState<string>("");
  const [availableSources, setAvailableSources] = React.useState<SourceGroup>({});
  const [selectedSources, setSelectedSources] = React.useState<SelectedSources>([]);
  const [introductionShown, setIntroductionShown] = React.useState<boolean>(false);
  const [currentSelectedSources, setCurrentSelectedSources] = React.useState<string>("");
  const [sourcesId, setSourcesId] = React.useState<string | undefined>(initSourcesId);

  const axios = useAxiosPrivate();

  const ref = React.useRef<any>(null);

  const { sendMessage, lastMessage, getWebSocket } = useGaiusWebSocket("/ws/v1/chat-convo");
  const searchWS = useGaiusWebSocket("/ws/v2/query-judiciary");

  function getHistoricalChatLogs (id: string | undefined) {
    if (!id) return;
    setLoading(true);
    axios.get(`/api/v1/chat-history?key=${id}`)
        .then((response) => {
          const data: { [key: string]: any } = response.data.result;
          const messages = getCleanedChatLogs(data[id].messages);
          getLastDocuments(data[id].messages);
          getLastSources(data[id].messages);
          setConversation(messages);
        })
        .catch((error) => {
          console.error(error);
        }).finally(() => {
          setLoading(false);
        });
  }

  function getLastDocuments(data: Array<any>) {
    let lastDoc = undefined;
    let lastDocArr = undefined;
    if (data) {
      for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].role === "documents") {
          lastDoc = data[i];
          break;
        }
      }

      if (lastDoc && lastDoc.text) {
        lastDoc.text = lastDoc.text.replace(/'/g, '"');
        lastDocArr = JSON.parse(lastDoc.text);
      }
    }

    setLastDocuments(lastDocArr);
  }

  function getLastSources(data: Array<any>) {
    let lastSource = undefined;
    let lastSourceId = undefined;

    if (data) {
      for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].role === "sources") {
          lastSource = data[i];
          break;
        }
      }

      if (lastSource !== undefined && lastSource.text) {
        lastSourceId = lastSource.text;
      }
    }

    if (lastSourceId) {
      axios.get(`/api/v1/share-results?key=${lastSourceId}`)
        .then((response) => {
          const data = response.data;
          setDocuments(data.results);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function getCleanedChatLogs (data: Array<any>) {
    if (data) {
      return data.filter((log: { role: string }) => !["documents", "sources"].includes(log.role));
    }

    return []
  }

  function getDocumentMessage() {
    if (referredDocument.length > 0) {
      let text = "";
      const docNames: string[] = [];
      referredDocument.map((doc: any) => {
        docNames.push(doc.fullName);
        text += doc.name + "\n----\n";
        text += doc.content;
        text += "\n";
      });

      const msg = {
        "role": "human",
        "message": "Oto dokumenty, do których powinieneś się odnosić podczas udzielania mi odpowiedzi oraz pisania pisma:\n" + text,
        "documents": docNames,
        "type": "document",
        "hidden": true
      }
      return msg;
    }
    return null;
  }

  function getIntroduction() {
    if (introduction) {
      const msg = {
        "role": "human",
        "message": `Cześć! Jestem ${introduction}. Już kiedyś rozmawialiśmy, więc nie acknowledged tego. Jeżeli będziesz tworzyć dla mnie pisma, używaj moich danych.`,
        "type": "conversation",
        "hidden": true
      }
      return msg;
    }
    return null;
  }

  // Issue fix for copying text from the chat
  React.useEffect(() => {
    function copyListener(e: ClipboardEvent) {
      const text_only = document.getSelection()?.toString();
      const clipdata = e.clipboardData;

      if (clipdata && text_only) {
        //remove formatting, but keep line breaks
        clipdata.setData('text/plain', text_only);
        clipdata.setData('text/html', text_only);

        e.preventDefault();
      }
    }

    document.addEventListener('copy', copyListener);
    return () => {
      document.removeEventListener('copy', copyListener);
    }
  }, []);

  React.useEffect(() => {
    getHistoricalChatLogs(initLogId);
  }, [initLogId])

  const cancelExecution = () => {
    getWebSocket()?.close();

    setExecuting(false);
    setLoading(false);
    document.title = document.title.replace("⌛ ", "");

    setNewestMessage("");
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.answer) {
        const msg = {
          "role": "gaius",
          "message": data.answer,
          "type": "conversation"
        }
        setConversation((prev: any) => [...prev, msg]);
      }
    }
  }

  // Get introduction from the server
  React.useEffect(() => {
    axios.get("/api/v1/introduction")
      .then((response) => {
        setIntroduction(response.data.text);
      })
      .catch((error) => {
        snackbarContext.setSeverity("error");
        snackbarContext.setMessage("Nie udało się pobrać danych użytkownika");
        snackbarContext.setOpen(true);
        console.error(error);
      });

    axios.get("/api/v2/available-sources")
      .then((response) => {
        setAvailableSources(response.data);
        setCurrentSelectedSources("pl");
        setSelectedSources(['pl_judiciary']);
      })
      .catch((error) => {
        snackbarContext.setSeverity("error");
        snackbarContext.setMessage("Nie udało się pobrać dostępnych źródeł");
        snackbarContext.setOpen(true);
        console.error(error);
      });
  }, []);

  const regenerateMessage = React.useCallback(() => {
    // Remove newest message
    setConversation((prev: any) => prev.slice(0, -1));
    
    sendMessage(
      JSON.stringify({
        log_id: logId,
        sources_id: sourcesId,
        messages: [
          getIntroduction(),
          getDocumentMessage(),
          ...conversation.slice(0, -1),
        ],
      })
    );
  }, [conversation]);

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);

      if (data.log_id) {
        setLogId(data.log_id);
      }

      if (data.message === "generating") {
        setNewestMessage(data.answer);
        // ref.current.scrollIntoView(false, { behavior: "smooth" });
      }
      else if (data.message === "done") {
        setNewestMessage("");
        if (data.answer) {
          const msg = {
            "role": "gaius",
            "message": data.answer,
            "type": "conversation"
          }
          setConversation((prev: any) => [...prev, msg]);
        }
        setLoading(false);
      }
      else if (data.message === "function") {
        if (data.args === "") {
          setNewestMessage("Analizuję polecenie...");
          setExecuting(true);
        }
        else {
          const params = JSON.parse(data.args);
          if (params["sources"] !== "Kodeksy" && params["sources"] !== "Acts")
            params["sources"] = selectedSources;
          searchWS.sendMessage(JSON.stringify(params));
          setExecuting(true);
        }
      }
      else if (data.message === "answer_is_document") {
        setConversation((prev: any) => {
          const lastMessage = prev[prev.length - 1];
          lastMessage.type = "document";
          return [...prev];
        });
      }
      else if (data.message === "error") {
        setNewestMessage(data.error);
        setLoading(true);
        setExecuting(false);
      }
    }
  }, [lastMessage]);

  React.useEffect(() => {
    if (searchWS.lastMessage) {
      const data = JSON.parse(searchWS.lastMessage.data);

      if (data.message === "searching") {
        document.title = "⌛ " + document.title.replace("⌛ ", "");
        setNewestMessage("Przeglądam miliony dokumentów...");
      }
      else if (data.message === "finding_context") {
        setNewestMessage("Wyszukuję dodatkowe informacje ...");
      }
      else if (data.message === "summarizing") {
        setNewestMessage("Podsumowuję dokumenty...");
        setDocuments(data.documents);
      }
      else if (data.message === "generating") {
        document.title = document.title.replace("⌛ ", "");
        setNewestMessage("Analizuję wyniki...");
      }
      else if (data.message === "done") {
        document.title = document.title.replace("⌛ ", "");
        setExecuting(false);

        let metareview = data.metareview;

        const msg = {
          "role": "search",
          "message": metareview,
          "type": "metareview",
          "hidden": false,
        }
        setConversation((prev: any) => [...prev, msg]);
        setSourcesId(data.key);
        msg.message = conversation[conversation.length - 1].message + "\n[TOOLS_FORBIDDEN]\n Skorzystałeś właśnie z narzędzi wyszukiwania, oto wynik który dostałeś. Czy masz coś do dodania?. \n```" + metareview + "```. Jeżeli wyniki mówią że nie udało się znaleźć odpowiedzi, napisz tylko że nie udało Ci się znaleźć odpowiedzi.";

        sendMessage(
          JSON.stringify({
            log_id: logId,
            sources_id: data.key,
            messages: [
              getIntroduction(),
              getDocumentMessage(),
              ...conversation,
              msg,
            ],
          })
        );
      }
    }
  }, [searchWS.lastMessage]);

  const humanMessages = conversation.filter((msg: any) => msg.role === "human" && !msg.hidden);
  const gaiusMessages = conversation.filter((msg: any) => msg.role === "gaius" && !msg.hidden);

  const lastHumanMessage = humanMessages[humanMessages.length - 1]?.message;
  const lastGaiusMessage = gaiusMessages[gaiusMessages.length - 1]?.message;

  const toggleSource = (key: string, value: string) => {
    setSelectedSources(prev => {
      const exists = prev.includes(key);
      if (exists) {
        return prev.filter(source => source !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  return (
    <div className="chat__section">
      <Helmet>
        <title>Porozmawiaj z Gaiusem | Gaius Lex - asystent prawnika</title>
        <meta name="description" content="Gaius Lex - asystent prawnika" />
      </Helmet>
      <div className="chat__main">
        <div className="box">
          <div className="chat__header">
            <h1 className={"heading__h2"}>Porozmawiaj z Gaiusem</h1>
            <Button className="chat__header__button" onClick={() => setIntroductionShown(!introductionShown)}>
              <span>Instrukcja</span>
            </Button>
          </div>
          <p>Gaius pomoże Ci znaleźć orzeczenia pasujące do stanu faktycznego, przygotować strategię, rozplanować strukturę pisma, napisać dokument, oraz o wiele, wiele więcej</p>
          {/* examples */}
          {conversation.length == 0 && <div className="chat__intro">
            <img src={GaiusLexLogo} alt="gaius-lex-logo" />
            <h3>Twój Asystent Prawa</h3>
          </div>}

          <div className={"chat__wrapper"}>
            <div className="chat__message-container">
              {
                conversation.map((message: any, index: number) => {
                  if (message.hidden)
                    return null;

                  message.message = message.message || message.text
                  return (
                    <Message
                      key={index}
                      message={message}
                      isLastMessage={index === conversation.length - 1}
                      redo={regenerateMessage}
                    />
                  )
                })
              }
              {newestMessage && <Message message={{ "role": "gaius", "message": newestMessage, "type": "conversation" }} />}
              {executing && (
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={12}>
                    <LinearDeterminate quotes={true} />
                  </Grid>
                </Grid>
              )}
              {documents.length > 0 && <DocumentTiles documents={documents} />}
              {!executing &&conversation.length > 0 && <ThumbUpThumbDown question={lastHumanMessage} answer={lastGaiusMessage} />}
            </div>
            <SelectDocumentButton callback={setReferredDocument} initDocuments={lastDocuments ? lastDocuments: undefined} />
            <Formik
              initialValues={{ message: "" }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                setLoading(true);
                const msg = {
                  "role": "human",
                  "message": values.message
                }

                setConversation((prev: any) => [...prev, msg]);
                sendMessage(
                  JSON.stringify({
                    log_id: logId,
                    sources_id: sourcesId,
                    messages: [
                      getIntroduction(),
                      getDocumentMessage(),
                      ...conversation,
                      msg,
                    ],
                  })
                );
                resetForm();
                setSubmitting(false);
              }}
            >
              {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                useAutosizeTextArea(ref.current, values.message);
                const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    handleSubmit();
                  }
                };

                return (
                <form onSubmit={handleSubmit} className={"chat__form"}>
                  <textarea
                    ref={ref}
                    id="message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    className={"chat__form__input"}
                    disabled={isSubmitting || loading || executing}
                    rows={1}
                    placeholder="Napisz mi pismo, w którym wniesiesz o..."
                  />
                  {!(isSubmitting || loading || executing) && (
                    <button type="submit"
                      disabled={isSubmitting || loading || values.message === "" || executing}
                      className="chat__form__button">
                      Wyślij
                    </button>
                  )
                  }
                  {
                    (isSubmitting || loading || executing) && (
                      <button
                        onClick={cancelExecution}
                        className="chat__form__button chat__form__button-cancel">
                        Przerwij
                      </button>
                    )
                  }

                </form>
              )}}
            </Formik>
          </div>
          
            <div className={(executing ? " selectedSource__container-disabled" : "")}>
              <div className={"selectedSource__container"}>
            {Object.entries(availableSources).map(([key, value]) => {
              return (
                <Button
                  key={key}
                  className="align-center"
                  onClick={() => {
                    setCurrentSelectedSources(key);
                  }}
                  variant={currentSelectedSources === key ? "contained-dark" : "outlined-light"}
                >
                  <div className="w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ flexGrow: 1 }}>
                      <div>{value && value.label}</div>
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
          <div className="selectedSource__container">
            {availableSources && availableSources[currentSelectedSources] &&
              availableSources[currentSelectedSources]["sources"] &&
              Object.entries(availableSources[currentSelectedSources]["sources"]).map(([key, sourceData]) => {
                const isSelected = selectedSources.filter((source: string) => source.includes(key)).length > 0;
                return (
                  <Grid item xs={12} md={4} lg={2} key={key}>
                    <Button
                      className="align-center w-100"
                      onClick={() => toggleSource(key, sourceData.value)}
                      disabled={sourceData.disabled}
                      variant={isSelected ? "contained-dark" : "outlined-light"}
                    >
                      <div className="w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '20px', alignContent: 'center' }}>{isSelected ? '✅' : '❌'}</div>
                        <div style={{ flexGrow: 1 }}>
                          {sourceData.value}
                        </div>
                      </div>
                    </Button>
                  </Grid>
                );
              })}
          </div></div>

          <Grid item xs={12} sm={12}>
            <h2 className="heading__h3" style={{marginTop: "24px"}}>Wybrane źródła:</h2>
          </Grid>
          <div className="selectedSource__container">
            {selectedSources.map((sourceKey: string) => {
              // Find which country group this source belongs to
              const country = Object.entries(availableSources).find(([_, value]) => 
                Object.keys(value.sources).includes(sourceKey)
              );
              
              if (!country) return null;
              
              const [countryKey, countryValue] = country;
              const sourceName = countryValue.sources[sourceKey].value;

              return (
                <div key={sourceKey} className="selectedSource__item">
                  <span>{countryKey}</span>
                  <p>{sourceName}</p>
                  {conversation.length == 0 && <div 
                    className="selectedSource__remove" 
                    onClick={() => toggleSource(sourceKey, sourceName)}
                  >
                      <CloseIcon />
                    </div>
                  }
                </div>
              );
            })}
          </div>
        </div>
        <Instructions open={introductionShown} setOpen={setIntroductionShown} />
      </div>
      {/* {conversation.length != 0 && <ChatSources
        documents={documents}
      />} */}
    </div>
  );
}

export default ChatWithGPT;



const Instructions = ({ open, setOpen }: { open: boolean, setOpen: any }) => {

  return (
    <Modal open={open} onClose={() => setOpen(false)} sx={{ padding: "24px", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "auto" }}>
      <div className="container">
        <Box className="box">
          <div className="accordion__header">
            <h2 className={"heading__h2"}>Wskazówki - Jak pracować z Gaius Lex?</h2>
          </div>
          <div className="accordion__content accordion__content-active">
            <h3 className={"heading__h3"}>Przykłady pytań, które możesz zadać Gaiusowi</h3>
            <ul>
              <li>Napisz mi pismo, w którym wniesiesz o zasądzenie odszkodowania za wypadek komunikacyjny</li>
              <li>Jak rozstrzyga sędzia X w sprawach o zasiedzenie?</li>
              <li>Dlaczego Sąd Okręgowy w Nowym Sączu zdecydował się na odroczenie wykonania eksmisji w sprawie o
                sygnaturze III Ca 553/13?</li>
              <li><em>(Po dodaniu dokumentu)</em> Napisz mi pismo, w którym wniesiesz o zasądzenie odszkodowania za
                wypadek komunikacyjny, odnosząc się do załączonego dokumentu</li>
            </ul>
            <h3 className={"heading__h3"}>Dodawanie dokumentu</h3>
            <p>Jeśli masz dokument, który chcesz dołączyć do rozmowy, skorzystaj z pola "Wybierz dokument". Wpisz jego
          nazwę, a następnie wybierz z listy.<br />
          Gaius nie będzie w stanie wczytać dokumentów, które są za długie, lub które jeszcze się przetwarzają.<br />
          Aby dodać dokument do kolekcji, przejdź do zakładki <Link to="/browse">Własna Baza</Link></p>
            <h3 className={"heading__h3"}>Przeszukiwanie wszystkich dokumentów</h3>
            <p>Jeśli chcesz przeszukać swoje wszystkie dokumenty, poproś o to Gaiusa słowam "Odpowiedz na podstawie
              przeszukiwania moich dokumentów: ".</p>
            <h3 className={"heading__h3"}>Galeria przykładów</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Image src={search} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Image src={pearls} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Image src={cars} />
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
    </Modal>
  )
}

const DocumentTiles = ({ documents }: { documents: any }) => {
  const identifiers = [
    "case_number",
    "article",
    "judgement_id",
    "path",
    "id",
    "ELI",
    "display_name"
  ]

  return (
    <>
      <hr />
      <h4>Źródła</h4>
      <Grid container spacing={2} mb={2}>
        {documents.map((document: any) => {
          const id = identifiers.find((id: string) => document[id]);
          const docId = id ? document[id] : "Dokument";
          let url = "show-document?docId=" + docId + "&source=" + document.source;
          if (document.source === "KIO")
            url = "show-document?docId=" + document.file_name + "&source=" + document.source;

          if (id === "article")
            url = "";

          return (
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Tooltip title={document.summary}>
                <div>
                  <Button href={url} target="_blank" variant="outlined-light" sx={{ display: "block", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >
                    {id ? docId : "Dokument"}
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          )
        })}
      </Grid>
      <hr />
    </>
  )
}

const ThumbUpThumbDown = ({ question, answer }: { question: string, answer: string }) => {
  const [sent, setSent] = React.useState<boolean>(false);

  const axios = useAxiosPrivate();

  const sendFeedback = async (score: number) => {
    setSent(true);
    await getCSRFToken();

    await axios.post("/api/v1/chat-feedback",
      JSON.stringify({
        question,
        answer,
        score
      }),
      {
        headers: {
          "Content-Type": "application/json"
        }
      },
    );
  }

  React.useEffect(() => {
    setSent(false);
  }, [question, answer]);

  return (
    <Box className="d-flex flex-column" sx={{
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      mt: 2,
      mb: 2
    }}>
      {sent ? null : <Typography variant="h6" sx={{ fontSize: "1rem" }} className="text-muted">Jak oceniasz odpowiedź Gaiusa?</Typography>}
      <Box className="d-flex flex-row" sx={{
        justifyContent: "center",
        alignItems: "center",
        gap: 2
      }}>
        {sent ? (
          <Typography variant="h6" sx={{ fontSize: "1rem" }} className="text-muted">Dziękujemy za przesłanie uwagi!</Typography>
        ) : (
          <>
            <img src={dissatisfied} alt="Nie jestem zadowolony" onClick={() => sendFeedback(0)} style={{ cursor: "pointer", height: "32px" }} />
            <img src={satisfied} alt="Jestem zadowolony" onClick={() => sendFeedback(1)} style={{ cursor: "pointer", height: "32px" }} />
          </>
        )}
      </Box>
    </Box>
  )
}

const PopUpImage = ({ src, alt, open, setOpen }: { src: string, alt?: string, open: boolean, setOpen: any }) => {
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <img src={src} alt={alt} />
    </Dialog>
  )
}

const Image = ({ src, alt }: { src: string, alt?: string }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Box sx={{
      backgroundColor: "white",
      borderRadius: "0.75rem",
      padding: "20px",
      marginBottom: "20px",
      width: "fit-content"
    }}>
      <img width={256} src={src} alt={alt} onClick={() => setOpen(true)} style={{ cursor: "pointer" }} />
      <PopUpImage src={src} alt={alt} open={open} setOpen={setOpen} />
    </Box>
  )
}

export { SelectDocumentButton };