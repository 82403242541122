import {
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popper,
  ClickAwayListener,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import getCSRFToken from "../../stores/CSRFStore";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import CloseIcon from "@mui/icons-material/Close";

const SearchBar = () => {
  const axios = useAxiosPrivate();
  const snackbar = React.useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchResults, setSearchResults] = React.useState<
    null | { url: string; headline: string; highlight: string }[]
  >(null);
  const [inputValue, setInputValue] = React.useState<null | string>(null);
  const [searchedValue, setSearchedValue] = React.useState<null | string>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "search-bar-popper" : undefined;

  async function search() {
    setIsLoading(true);
    await getCSRFToken();
    axios
      .get(`api/v1/web-search?q=${inputValue}`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setSearchResults(data.result);
      })
      .catch((error) => {
        console.log(error);
        snackbar.setMessage("Wystąpił błąd podczas wyszukiwania");
        snackbar.setSeverity("error");
        snackbar.setOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
        setSearchedValue(inputValue);
      });
  }

  function getSearchIcon() {
    if (isLoading)
      return (
        <IconButton sx={{ p: "10px" }} aria-label="loading-search">
          <CircularProgress size={24} sx={{ margin: "0 auto" }} />
        </IconButton>
      );

    return inputValue && inputValue === searchedValue ? (
      <IconButton
        sx={{ p: "10px" }}
        aria-label="clear-search"
        onClick={(e) => {
          e.preventDefault();
          setInputValue(null);
          setSearchResults(null);
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : (
      <IconButton
        sx={{ p: "10px" }}
        aria-label="search"
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          search();
        }}
      >
        <SearchIcon />
      </IconButton>
    );
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleClose();
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper
          aria-describedby={id}
          component="form"
          onClick={handleClick}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "60%",
            float: "left",
            borderRadius: "0.75rem",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Wyszukaj..."
            inputProps={{ "aria-label": "wyszukaj" }}
            value={inputValue ? inputValue : ""}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          {getSearchIcon()}
        </Paper>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          sx={{ width: "70%", zIndex: 2000, maxHeight: "500px" }}
        >
          <Paper sx={{ p: 2, mt: 0.5, borderRadius: "0.75rem" }}>
            <IconButton sx={{ float: "right" }} onClick={handleClose}>
              <CloseIcon color="primary" />
            </IconButton>
            {searchResults?.length === 0 && "Brak wyników wyszukiwania"}
            {searchResults ? (
              <List sx={{ maxHeight: 600, overflow: "auto" }}>
                {searchResults.map((result) => (
                  <>
                    <ListItem>
                      <ListItemButton
                        component="a"
                        href={result.url}
                        sx={{ display: "block" }}
                      >
                        <Stack direction="row" spacing={2}>
                          <>
                            <div
                              style={{ color: "#8D9DA8" }}
                              dangerouslySetInnerHTML={{ __html: result.headline }}
                            />
                            <i style={{ fontSize: 14 }}>
                              <strong>url: {result.url}</strong>
                            </i>
                          </>
                          <i>
                            {result.highlight}
                          </i>
                        </Stack>
                      </ListItemButton>
                    </ListItem>
                    <Divider sx={{ borderColor: "black" }} />
                  </>
                ))}
              </List>
            ) : (
              "Wyniki wyszukiwania"
            )}
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default SearchBar;
