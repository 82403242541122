import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import getCSRFToken from "../../stores/CSRFStore";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router";
import StarterKit from "@tiptap/starter-kit";
import {
  InsertableTagExtension,
  OrdinalTagExtension,
  BoldOnlyExtension,
} from "./Extension";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import "./index.scss";
import { default as GenericButton } from "../../components/generic/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DOMParser } from "prosemirror-model";
import LinearDeterminate from "../../components/LinearBuffer";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import useUserData from "../../hooks/useUserData";
import { Transaction, TextSelection } from "prosemirror-state";
import { AddMarkStep, RemoveMarkStep, Step } from "prosemirror-transform";
import { Editor } from "@tiptap/core";
import { Node as ProseMirrorNode } from "prosemirror-model";

interface IEnt {
  label: string;
  start: number;
  end: number;
  lemma: string;
  id: number;
}

interface IEntities {
  sentence_entities: {
    text: string;
    ents: IEnt[];
    startingCharIndex?: number;
    endingCharIndex?: number;
  }[];
}

interface IMarker {
  marker: string;
  entId?: number | "";
}

const Anonymizer = () => {
  const axios = useAxiosPrivate();
  const location = useLocation();
  const initialText = location.state?.initialText;
  const path = location.state?.path;
  const statusId = location.state?.statusId;
  const initialized = React.useRef(false);
  const editor = useEditor({
    extensions: [
      StarterKit,
      InsertableTagExtension,
      OrdinalTagExtension,
      BoldOnlyExtension,
    ],
    content: initialText?.replaceAll("\n", "<br />") || "",
    onUpdate: ({ transaction }) => {
      const isBold = isBoldAction(transaction);

      if (isBold) {
        if (editor && markAllOccurences)
          makeOccurrencesBold(transaction, editor);

        getAllMarkers();
      }
    },
    editable: true,
  });
  const [entities, setEntities] = React.useState<undefined | IEntities>();
  const [anonymized, setAnonymized] = React.useState<string[]>([]);
  const [highlight, setHighlight] = React.useState<number | undefined>();
  const [markers, setMarkers] = React.useState<IMarker[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [markAllOccurences, setMarkAllOccurences] = React.useState(true);
  const { userData } = useUserData();
  const navigate = useNavigate();
  const snackbar = React.useContext(SnackbarContext);

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getCSRFToken().then(async () => {
        setLoading(true);
        await handleAnonymize();
        setLoading(false);
      });
    }
  }, [initialText]);

  React.useEffect(() => {
    document.querySelectorAll(".insertable-tag.highlighted").forEach((el) => {
      el.classList.remove("highlighted");
    });

    const element = document.getElementById(`marker-A${highlight}`);
    if (element && element.parentElement) {
      element.parentElement.classList.add("highlighted");
    }
  }, [highlight]);

  React.useEffect(() => {
    if (userData.defaultAnonymization) {
      setLoading(true);
      if (entities) handleDefaultAnonymization();
    }
  }, [entities]);

  const makeOccurrencesBold = (transaction: Transaction, editor: Editor) => {
    if (transaction.getMeta("programmatic")) return;

    const { doc, selection } = transaction;
    const { from, to } = selection;

    const isBoldAdded = transaction.steps.some((step: Step) => {
      if (step instanceof AddMarkStep) {
        return step.mark.type.name === "bold" && step.from < step.to;
      }
      return false;
    });

    const isBoldRemoved = transaction.steps.some((step: Step) => {
      if (step instanceof RemoveMarkStep) {
        return step.mark.type.name === "bold" && step.from < step.to;
      }
      return false;
    });

    const selectedText = doc.textBetween(from, to, " ");

    if (!selectedText) return;

    // Find all occurrences of the same text in the entire document
    const occurrences: { from: number; to: number }[] = [];
    doc.descendants((node: ProseMirrorNode, pos: number) => {
      if (node.isText && node.text) {
        let index = node.text.indexOf(selectedText);
        while (index !== -1) {
          occurrences.push({
            from: pos + index,
            to: pos + index + selectedText.length,
          });
          index = node.text.indexOf(selectedText, index + 1);
        }
      }
    });

    // Apply or remove bold to all occurrences depending on whether bold was added or removed
    occurrences.forEach(({ from, to }) => {
      const tr = editor.state.tr
        .setSelection(TextSelection.create(editor.state.doc, from, to))
        .setMeta("programmatic", true);

      if (isBoldAdded) {
        tr.addMark(from, to, editor.schema.marks.bold.create()); // Apply bold
      } else if (isBoldRemoved) {
        tr.removeMark(from, to, editor.schema.marks.bold); // Remove bold
      }

      editor.view.dispatch(tr);
    });
  };

  const isBoldAction = (transaction: Transaction) => {
    const { steps } = transaction;

    return steps.some((step: Step) => {
      const stepJSON = step.toJSON();

      if (
        stepJSON.stepType === "addMark" ||
        stepJSON.stepType === "removeMark"
      ) {
        return stepJSON.mark?.type === "bold";
      }

      return false;
    });
  };

  const handleHighlightUpdate = (newState: number | undefined) => {
    setHighlight(newState);
  };

  const getAllMarkers = () => {
    let markers: IMarker[] = [];

    if (editor) {
      const htmlString = editor.getHTML();

      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;

      const anonymizerMarkers: NodeListOf<HTMLElement> =
        tempDiv.querySelectorAll("strong");
      markers = Array.from(anonymizerMarkers).map((span) => {
        const parentId = span.parentElement?.id;
        const entId = parentId?.split("-").pop();

        return { marker: span.innerHTML || "", entId: entId && +entId };
      });
    }

    setMarkers(markers);
  };

  const handleDefaultAnonymization = async () => {
    if (entities != undefined && entities.sentence_entities != undefined) {
      const ents = entities.sentence_entities.map((ent) => ent.ents);

      let anonymizeEntities = ents.map((sent) =>
        sent.map((ent) => ({ start: ent.start, end: ent.end }))
      );

      const sentenceEntities = entities.sentence_entities.map(
        (ent) => ent.text
      );
      const data = {
        sentence_entities: sentenceEntities,
        anonymize_entities: anonymizeEntities,
        path: path,
        status_id: statusId,
      };

      axios
        .post("anonymizer/api/v1/commit-anonymized", JSON.stringify(data), {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          navigate("/browse");
        })
        .catch((error) => {
          snackbar.setMessage("Wystąpił błąd podczas anonimizacji pliku");
          snackbar.setSeverity("error");
          snackbar.setOpen(true);
        });
    }
  };

  const handleAnonymize = async () => {
    const resp = await axios.post(
      "anonymizer/api/v1/get-anonymized-entities",
      JSON.stringify({ text: initialText }),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = JSON.parse(resp.request.response);

    const anonymized = getAnonimized(json);
    setAnonymized(anonymized)

    transformEntities(json);
  };

  const getAnonimized = (entities: IEntities) => {
    let anonymized: string[] = [];

    entities.sentence_entities.forEach((sentence) => {
      sentence.ents.forEach((entity) => {
        const start = entity.start; //=== 0 ? entity.start: entity.start - 1;
        const end = entity.end;

        anonymized.push(sentence.text.substring(start, end));
      });
    });

    return anonymized;
  }

  const revertTransformEntities = (htmlContent: string) => {
    let newText = htmlContent;

    if (entities != undefined && entities.sentence_entities != undefined) {

      newText =
        htmlContent?.replace(
          /<insertable-tag.*?id="(.*?)".*?<\/insertable-tag>/g,
          (match, id) => {
            const idx: number = +id.replace(/^A/, "") - 1;
            const text = anonymized.length > idx ? anonymized[idx] : "";

            return text;
          }
        ) || "";

      newText = newText.replace(/<(?!strong\s*|\/strong>)[^>]+>/g, "");
      newText = newText.replace(/<br\s*\/?>/g, "\n").replace(/&nbsp;/g, " ");
    }

    return newText;
  };

  const removeStrongTagsAndGetIndices = (inputText: string) => {
    const regex = /<strong>(.*?)<\/strong>/g;
    let result = [];
    let match;
    let cleanedText = inputText;
    let offset = 0;

    while ((match = regex.exec(inputText)) !== null) {
      const contentBetweenTags = match[1];
      const originalStartIndex = match.index;
      const originalEndIndex = regex.lastIndex - 1;

      const startInCleanedText = originalStartIndex - offset;
      const endInCleanedText = startInCleanedText + contentBetweenTags.length;

      result.push({
        start: startInCleanedText,
        end: endInCleanedText,
      });

      cleanedText = cleanedText.replace(match[0], contentBetweenTags);

      offset += match[0].length - contentBetweenTags.length;
    }

    return { cleanedText, indices: result };
  };

  const splitBySpanTags = (inputText: string) => {
    const regex = /<span id="text-anonymizer-content-\d+">(.*?)<\/span>/g;
    const result = [];
    let match;

    while ((match = regex.exec(inputText)) !== null) {
      result.push(match[1]);
    }

    return result;
  };

  const handleAccept = async () => {
    if (entities != undefined && entities.sentence_entities != undefined) {
      const ents = entities.sentence_entities.map((ent) => ent.ents);

      let anonymizeEntities = ents.map((sent) =>
        sent.map((ent) => ({ start: ent.start, end: ent.end }))
      );

      const html = editor?.getHTML();
      const spans = html ? splitBySpanTags(html) : [];

      spans.forEach((span, index) => {
        const revertedEntities = revertTransformEntities(span);
        const { cleanedText, indices } =
          removeStrongTagsAndGetIndices(revertedEntities);

        anonymizeEntities[index].push(...indices);
      });

      const sentenceEntities = entities.sentence_entities.map(
        (ent) => ent.text
      );
      const data = {
        sentence_entities: sentenceEntities,
        anonymize_entities: anonymizeEntities,
        path: path,
        status_id: statusId,
      };

      axios
        .post("anonymizer/api/v1/commit-anonymized", JSON.stringify(data), {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          navigate("/browse");
        })
        .catch((error) => {
          snackbar.setMessage("Wystąpił błąd podczas anonimizacji pliku");
          snackbar.setSeverity("error");
          snackbar.setOpen(true);
        });
    }
  };

  const removeInsertableTagById = (html: string, id: string): string => {
    const idx: number = +id.replace(/^A/, "") - 1;
    const text = anonymized.length > idx ? anonymized[idx] : "";

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const insertableTag = tempDiv.querySelector(`#${id}`);
    if (insertableTag) {
      insertableTag.replaceWith(text);
    }

    return tempDiv.innerHTML;
  }

  const transformExistingHTML = (json: IEntities, id?: string) => {
    const html = editor?.getHTML();
    const result = html && removeInsertableTagById(html, `A${id}`);
    

    setEntities(json);
    if (result && result.length !== 0) {
      setEditorContentWithMetadata(result);
    }
  };

  const transformEntities = (json: IEntities) => {
    let finalText: string[] = [];
    let mainIndex = 0;
    let updated = json;

    if (json != undefined && json.sentence_entities != undefined) {
      updated.sentence_entities = json.sentence_entities.map(
        (entity, entityId) => {
          let text = entity.text;
          let step = 0;

          entity.ents = entity.ents.map((ent) => {
            const start = ent.start - step;
            const end = ent.end - step;
            mainIndex++;
            const prefixTag = `<insertable-tag id="A${mainIndex}" label="A${mainIndex}" className="insertable-tag ${
              highlight === mainIndex ? "highlighted" : ""
            }" text="`;
            const suffixTag = `"></insertable-tag>`;
            step =
              step +
              (end - start - ent.label.length) -
              prefixTag.length -
              suffixTag.length;

            text = removeSubstring(
              text,
              ent.label,
              start,
              end,
              prefixTag,
              suffixTag
            );

            ent.id = mainIndex;
            return ent;
          });

          text = `<span id="text-anonymizer-content-${entityId}">${text}</span>`;

          finalText.push(text?.replaceAll("\n", "<br />"));
          return entity;
        }
      );

      setEntities(updated);
    }

    if (finalText.length !== 0) {
      setEditorContentWithMetadata(finalText.join(" "));
    }
  };

  const setEditorContentWithMetadata = (content: string) => {
    if (editor) {
      const transaction = editor.state.tr
        .setMeta("programmatic", true)
        .replace(0, editor.state.doc.content.size);

      const element = document.createElement("div");
      element.innerHTML = content;
      const docFragment = DOMParser.fromSchema(editor.schema).parse(element);

      transaction.insert(0, docFragment.content);

      editor.view.dispatch(transaction);
    }
  };

  const removeSubstring = (
    text: string,
    label: string,
    startIndex: number,
    endIndex: number,
    prefixTag: string,
    suffixTag: string
  ): string => {
    let prefix = text.slice(0, startIndex);
    let suffix = text.slice(endIndex, text.length - 1);
    let divider = label ? label : "";

    const finalText = prefix + prefixTag + divider + suffixTag + suffix;

    return finalText;
  };

  return (
    <>
      <Helmet>
        <title>Anonimizacja | Gaius-Lex</title>
      </Helmet>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Anonimizacja dokumentu
            </Typography>
            {userData.defaultAnonymization ? (
              <Typography fontSize={14} mb={2}>
                Plik zostanie automatycznie zanonimizowany, po zakończeniu
                procesu zostaniesz przekierowany do Własnej Bazy.
              </Typography>
            ) : (
              <Typography fontSize={14} mb={2}>
                Zaznacz w edytorze słowa, które mają zostać zanonimizowane, a
                następnie kliknij <span id="anonymize-badge">Zanonimizuj</span>.
                Terminy zaznaczone na czerwono zostały automatycznie
                wyselekcjonowane do anonimizacji.
              </Typography>
            )}
            {loading ? (
              <div id="anonymize-loading-bar">
                <Typography
                  variant="body1"
                  component="p"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  Ładowanie dokumentu ...
                </Typography>
                <LinearDeterminate />
              </div>
            ) : (
              <Card className="flat-card" sx={{ p: 2 }}>
                {editor && (
                  <BubbleMenu
                    className="bubble-menu"
                    tippyOptions={{
                      duration: 1000,
                      delay: 0,
                      onShow: () => {
                        const isBold = editor?.isActive("bold");

                        const element =
                          document.getElementById("anonymize-button");
                        if (element) {
                          element.innerHTML = isBold
                            ? "Deanonimizuj"
                            : "Zanonimizuj";
                        }
                      },
                    }}
                    updateDelay={0}
                    editor={editor}
                  >
                    <button
                      onClick={() => {
                        editor?.chain().focus().toggleBold().run();
                      }}
                      id="anonymize-button"
                      className={editor?.isActive("bold") ? "is-active" : ""}
                    >
                      Zanonimizuj
                    </button>
                  </BubbleMenu>
                )}
                <EditorContent editor={editor} />
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <GenericButton
              variant="outlined-light"
              className="w-100 mb-3"
              onClick={handleAccept}
              disabled={loading}
            >
              Zaakceptuj
            </GenericButton>
            <Card className="flat-card" sx={{ p: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={markAllOccurences}
                      onChange={() => setMarkAllOccurences(!markAllOccurences)}
                      disabled={loading}
                    />
                  }
                  label="Zaznacz wszystkie wystąpienia"
                />
                <FormHelperText>
                  Po zaznaczeniu jednego terminu, wszystkie jego pozostałe
                  wystąpienia zostaną zanonimizowane.
                </FormHelperText>
              </FormGroup>
            </Card>
            <Card className="flat-card" sx={{ p: 2, mt: 2 }}>
              <MarkersCard markers={markers} editor={editor} />
            </Card>
            <Card className="flat-card" sx={{ p: 2, mt: 2 }}>
              <ResultsCard
                entities={entities}
                setHighlight={handleHighlightUpdate}
                transformExistingHTML={transformExistingHTML}
                highlight={highlight}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const MarkersCard = ({ markers, editor }: { markers: IMarker[], editor: Editor | null }) => {
  const countOccurrences = (arr: IMarker[]) => {
    const countMap: { [key: string]: number } = {};

    arr.forEach((marker) => {
      countMap[marker.marker] = (countMap[marker.marker] || 0) + 1;
    });

    return Object.entries(countMap).map(([word, count]) => ({
      word,
      count,
    }));
  };

  const removeBoldWordOccurrences = (editor: Editor, word: string, x: number) => {
    const boldPositions: number[] = [];
    
    editor.state.doc.descendants((node: ProseMirrorNode, pos: number) => {
      if (node.isText) {
        const hasBoldMark = node.marks.some(mark => mark.type.name === 'bold');
        
        if (hasBoldMark && node.text && node.text.includes(word)) {
          boldPositions.push(pos);
        }
      }
    });

    if (boldPositions.length === 0) {
      console.error(`No bold occurrences of the word "${word}" found in the editor.`);
      return;
    }

    const boldPositionsToRemove = boldPositions.slice(0, x);

    const tr = editor.state.tr.setMeta("programmatic", true);
  
    boldPositionsToRemove.forEach(pos => {
      editor.state.doc.descendants((node, position) => {
        if (position === pos && node.isText && node.text && node.text.includes(word)) {
          tr.removeMark(position, position + node.nodeSize, editor.schema.marks.strong);
        }
      });
    });

    editor.view.dispatch(tr);
  }

  return (
    <Card
      className="markers-card"
      sx={{
        p: 2,
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
        borderTop: "1px solid #e0e0e0",
        display: "contents",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Anonimizowane Terminy
      </Typography>
      <Typography fontSize={14} mb={2}>
        Poniżej znajdują się terminy wybrane przez Ciebie do dalszej
        anonimizacji.
      </Typography>
      <Accordion
        className="shadow-0"
        defaultExpanded={false}
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          marginBottom: "1rem",
          padding: 0,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Terminy do anonimizacji ({markers.length})
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Typography variant="body1">
            {countOccurrences(markers).map(({ word, count }) => (
              <div className="text-anonymizer-candidate">
                {word} ({count})
                <IconButton
                  aria-label="delete"
                  style={{ marginLeft: "auto", color: "#fa6e68" }}
                  onClick={(event) => {
                    if (editor) removeBoldWordOccurrences(editor, word, count)
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

const ResultsCard = ({
  entities,
  setHighlight,
  highlight,
  transformExistingHTML,
}: {
  entities?: IEntities;
  setHighlight: (arg: number | undefined) => void;
  highlight?: number;
  transformExistingHTML: (arg: IEntities, id?: string) => void;
}) => {
  let count = 0;
  const [titleIndex, setTitleIndex] = React.useState(0);
  const entitiesCopy = entities && JSON.parse(JSON.stringify(entities));

  React.useEffect(() => {
    setTitleIndex(count);
  }, [entities]);

  React.useEffect(() => {
    document.addEventListener("click", (event: MouseEvent) => {
      const targetComponent = (event.target as HTMLElement).closest(
        ".text-anonymizer-result"
      ) as HTMLElement | null;

      if (!targetComponent) {
        setHighlight(undefined);
        return;
      }

      const index = Number(targetComponent.id.split("-").at(-1));
      setHighlight(index);
    });
  }, []);

  return (
    <Card
      className="result-card"
      sx={{
        p: 2,
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
        borderTop: "1px solid #e0e0e0",
        display: "contents",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Wyniki anonimizacji
      </Typography>
      <Typography fontSize={14} mb={2}>
        Poniżej znajdują się terminy, które zostały automatycznie wybrane do
        anonimizacji.
      </Typography>
      <Accordion
        className="shadow-0"
        defaultExpanded={false}
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          marginBottom: "1rem",
          padding: 0,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Terminy zanonimizowane ({titleIndex})
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Typography variant="body1">
            {entities &&
              entities.sentence_entities.length > 0 &&
              entities.sentence_entities.map((entity, entity_index) => {
                return entity.ents.map((ent, ent_index) => {
                  count++;
                  return (
                    <div
                      id={`marker-result-${ent.id}`}
                      className="text-anonymizer-result"
                      key={ent.id}
                    >
                      <span
                        className={`text-anonymizer-marker ${
                          highlight === ent.id} && "highlighted-marker"
                        }`}
                        onClick={(event) => {
                          document
                            .getElementById(`marker-risk-${ent.id}}`)
                            ?.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        A{ent.id}
                      </span>
                      <div className="d-flex flex-column">
                        <Typography variant="body1">{ent.label}</Typography>
                        <Typography variant="body2" className="text-muted">
                          {ent.lemma}
                        </Typography>
                      </div>
                      <IconButton
                        aria-label="delete"
                        style={{ marginLeft: "auto", color: "#fa6e68" }}
                        onClick={(event) => {
                          if (entitiesCopy)  { 
                            entitiesCopy.sentence_entities[entity_index].ents.splice(ent_index, 1); 
                            const targetComponent = (event.target as HTMLElement).closest(
                              ".text-anonymizer-result"
                            ) as HTMLElement | null;

                            const id = targetComponent?.id;
                            const index = id?.split("-").pop();

                            transformExistingHTML && transformExistingHTML(entitiesCopy, index)
                          }
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </div>
                  );
                });
              })}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default Anonymizer;
