import { Typography, TextField, Autocomplete, Box, Card, CardContent, Divider, CardActionArea, Stack } from "@mui/material";
import Button from "../../components/generic/Button";
import { Form, Formik, ErrorMessage } from "formik";
import { MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import * as Yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pl';

import useGaiusWebSocket from "../../hooks/useGaiusWebSocket";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LinearDeterminate from "../../components/LinearBuffer";
import { ReviewAnswerButton } from "../caseLawSearch/ReviewAnswerButton";
import DataUsageNoticeModal from "../../components/dataUsageNoticeModal";
import useUserData from "../../hooks/useUserData";

export enum Source {
  PL_JUDICIARY = "pl_judiciary",
  PL_CODEXES = "pl_codexes",
  PL_EUREKA = "pl_eureka",
  PL_OWN_DATABASE = "pl_own_database",
  PL_EURLEX = "pl_eurlex",
  PL_ACTS = "pl_acts",
}
type TabMetadata = {
  label: string,
  index: number
}


export const NAMES_MAPPING: Record<string, TabMetadata> = {
  [Source.PL_OWN_DATABASE]: {
    label: "Własne dane",
    index: 0,
  },
  [Source.PL_JUDICIARY]: {
    label: "Orzecznictwo",
    index: 1,
  },
  [Source.PL_EUREKA]: {
    label: "Interpretacje podatkowe",
    index: 2,
  },
  [Source.PL_CODEXES]: {
    label: "Kodeksy",
    index: 3,
  },
  [Source.PL_ACTS]: {
    label: "Ustawy",
    index: 4,
  },
  [Source.PL_EURLEX]: {
    label: "EurLex",
    index: 5,
  },
}


function InteractiveQuery({ cachedQuery }: { cachedQuery?: string }) {
  const [params, setParams] = useSearchParams();
  const query = params.get("query") || cachedQuery || "";

  const [isLoading, setIsLoading] = React.useState(false);
  const [result, setResult] = React.useState<any[]>([]);
  const [convoKey, setConvoKey] = React.useState<string>("");
  const [questionsAsked, setQuestionsAsked] = React.useState<Set<string>>(new Set());
  const [more, setMore] = React.useState(false);

  const [answers, setAnswers] = React.useState<{ [docId: string]: { question: string, answer: any[] } }>({});

  const { sendMessage, lastMessage } = useGaiusWebSocket("/ws/v1/query-judiciary-interactive")

  const [showDataUsageNotice, setShowDataUsageNotice] = React.useState<boolean>(false);
  const { userData } = useUserData();

  const handleSubmit = async (values: any) => {
    if (!!!userData.dataUsageNotice) {
      setShowDataUsageNotice(true);
      return;
    }

    const msg = {
      command: "search",
      factualState: values.factualState,
      sources: values.sources,
      dateBefore: values.dateBefore,
      dateAfter: values.dateAfter,
    }
    sendMessage(JSON.stringify(msg));
    setIsLoading(true);
    // @ts-ignore
    setParams({ sources: values.sources, query: values.factualState, ...params });
  }

  React.useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data);
      if (message.command === "initialize") {
        setConvoKey(message.key);
        setResult([]);
        setQuestionsAsked(new Set());
        setAnswers({});
      }
      if (message.command === "search") {
        setResult(message.documents);
        setMore(message.more);
        setIsLoading(false);
      }
      if (message.command === "summarize") {
        setAnswers({ ...answers, [message.doc_id]: { [message.question]: message.summary, ...answers[message.doc_id] } });
      }
      if (message.command === "summarize_end") {
      }
      if (message.command === "more") {
        setResult([...result, ...message.documents]);

        for (const question of questionsAsked) {
          const msg = {
            command: "summarize",
            question: question,
            documents: message.documents,
            key: convoKey,
          }
          sendMessage(JSON.stringify(msg));
        }
      }
    }
  }, [lastMessage]);

  return (
    <>
      <Helmet>
        <title>Znajdź odpowiedź | Gaius Lex - asystent prawnika</title>
        <meta name="description" content="Gaius Lex - asystent prawnika. Znajdź dokumenty powiązane z Twoim pytaniem." />
      </Helmet>
      <div id={"cls-form"} >
        <h1>
          Znajdź dokumenty powiązane z Twoim pytaniem
        </h1>
        <Typography variant="h5" color="text.secondary" className="pb-2" sx={{ fontWeight: "regular", fontSize: "1rem" }}>
          Wpisz tutaj swoje pytanie albo opis sytuacji. Gaius Lex znajdzie dla Ciebie adekwatne dokumenty.
        </Typography>
        <MDBRow className="pt-4">
          <Formik
            initialValues={{
              factualState: query,
              sources: [],
              // dateAfter: null,
              // dateBefore: null,
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object({
              factualState: Yup.string().required("Pole wymagane"),
            })
            }
          >
            {(formik) => {
              return (
                <>
                  <MDBRow style={{ width: "100%" }}>
                    <Form style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                      <TextField
                        label="Twoje pytanie"
                        id="factualState"
                        name="factualState"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.factualState}
                        className="w-100"
                        placeholder="po kim dziedziczy dziecko które zostało przysposobione?"
                        autoComplete="off"
                      />
                      <ErrorMessage name="factualState" component="div" />
                      <Autocomplete
                        id="sources"
                        options={[{
                          value: Source.PL_JUDICIARY,
                          label: "Orzecznictwo"
                        }, {
                          value: Source.PL_CODEXES,
                          label: "Kodeksy"
                        }, {
                          value: Source.PL_EUREKA,
                          label: "Interpretacje podatkowe"
                        }]}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        className="w-100 mt-2"
                        onChange={(event, value) => formik.setFieldValue("sources", [...formik.values.sources, value[0].value])}
                        renderInput={(params) => <TextField {...params} label="Źródło" />}
                        multiple
                      />
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                        <Stack direction={"row"} spacing={2} style={{ justifyContent: "center" }} className="my-2 w-100">
                          <DatePicker
                            label="Po dacie"
                            value={formik.values.dateAfter}
                            onChange={(date) => formik.setFieldValue("dateAfter", date)}
                          />
                          <DatePicker
                            label="Przed datą"
                            value={formik.values.dateBefore}
                            onChange={(date) => formik.setFieldValue("dateBefore", date)}
                          />
                        </Stack>
                      </LocalizationProvider> */}
                      <ErrorMessage name="question" component="div" />
                      <Button
                        className="mx-1 my-3"
                        id="search-button"
                        variant="contained-dark"
                        type="submit"
                        width="100%"
                        disabled={isLoading}
                      >Znajdź</Button>
                    </Form>
                  </MDBRow>
                </>
              )
            }
            }
          </Formik>
        </MDBRow>
      </div>
      {showDataUsageNotice && <DataUsageNoticeModal />}
      {isLoading && <LinearDeterminate maxTime={30} />}
      {
        result.length > 0 &&
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"} flexDirection={"column"}>
          <h6>Oceń odpowiedzi</h6>
          <Stack direction={"row"} spacing={2} style={{ justifyContent: "center" }}>
            <ReviewAnswerButton type="down" answerKey={convoKey} url="/api/v1/isearch-feedback" />
            <ReviewAnswerButton type="up" answerKey={convoKey} url="/api/v1/isearch-feedback" />
          </Stack>
        </Box>
      }
      <MDBRow>
        {
          result.length > 0 && (
            <Formik
              initialValues={{
                question: query,
              }}
              onSubmit={(data) => {
                setQuestionsAsked(new Set([...questionsAsked, data.question]));
                const msg = {
                  command: "summarize",
                  question: data.question,
                  documents: result,
                  key: convoKey,
                }
                sendMessage(JSON.stringify(msg));
              }}
            >
              {(formik) => {
                return (
                  <>
                    <Form style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: "4px" }}>
                      <TextField
                        label="Twoje pytanie"
                        id="question"
                        name="question"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.question}
                        className="w-100"
                        placeholder="po kim dziedziczy dziecko które zostało przysposobione?"
                        autoComplete="on"
                      />
                      <ErrorMessage name="question" component="div" />
                      <Button
                        className="mx-1 my-3"
                        id="search-button"
                        variant="outlined-light"
                        type="submit"
                        width="25%"
                      >Przeanalizuj</Button>
                    </Form>
                    <Divider />
                  </>
                )
              }}
            </Formik>
          )
        }
      </MDBRow>
      <MDBRow>
        {
          result.length > 0 && (
            formatData(result).map((data: any, key: any) => {
              return (
                <ResultElement key={key} data={data} questionsAsked={questionsAsked} answers={answers} />
              )
            })
          )
        }
      </MDBRow>
      {
        more && result.length !== 0 && (
          <MDBRow>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
              <Button
                className="mx-1 my-3"
                id="search-button"
                variant="contained-dark"
                type="submit"
                onClick={() => {
                  const msg = {
                    command: "more",
                    key: convoKey,
                  }
                  sendMessage(JSON.stringify(msg));
                }}
              >Wczytaj więcej</Button>
            </Box>
          </MDBRow>
        )
      }
    </>
  );
}

export default InteractiveQuery;

const ResultElement = ({ key, data, questionsAsked, answers }: { key: any, data: any, questionsAsked: Set<string>, answers: any }) => {
  const internalURL = data.internalUrl;

  return <MDBRow className="py-2 px-0" key={key}>
    <Card className="px-0 mx-2" style={{ borderRadius: "0.75rem", boxShadow: "none" }} >
      <CardContent className="d-flex flex-column">
        <Stack direction="row" spacing={2} style={{ justifyContent: "space-between" }} className="my-2 w-100">
          <Typography variant="h6" color="text.primary" display={"flex"} alignItems={"center"} width={"20%"}>
            {data.id}
          </Typography>
          <Typography variant="h6" color="text.secondary" fontSize={"1rem"} display={"flex"} alignItems={"center"} width={"20%"}>
            {data.citation}
          </Typography>
          <CardActionArea href={data.internalUrl} target="_blank" rel="noreferrer">
            <Typography variant="body2" color="text.primary" className="my-2" style={{ fontStyle: "italic" }}>
              <FormatQuoteIcon />(...){data.quote}(...)
            </Typography>
          </CardActionArea>
        </Stack>
        {
          (questionsAsked && data.source != "Kodeksy") && (
            <>
              <Divider />
              {Array.from(questionsAsked).map((question: string, key: number) => {
                return (
                  <Stack direction={"row"} spacing={2} style={{ justifyContent: "space-between" }} className="my-2 w-100">
                    <Typography key={"q-" + key} color="text.secondary" display={"flex"} alignItems={"center"} width={"30%"}>
                      {question}
                    </Typography>
                    <Typography key={"a-" + key} color="text.primary" display={"flex"} alignItems={"center"} width={"70%"} minHeight={"150px"}>
                      {(answers[data.id] && answers[data.id][question]) ? answers[data.id][question] : "Podsumowywanie..."}
                    </Typography>
                  </Stack>
                )
              })}
            </>
          )}
        <Stack direction="row" spacing={2} style={{ justifyContent: "space-between" }} className="my-2 w-100">
          <Typography variant="body2" color="text.primary" style={{ textAlign: "left" }} className="py-1">
            {data.date ? "Data:" : null} {data.date}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} style={{ justifyContent: "center" }} className="my-2 w-100">
          {
            data.url ? <Button
              className="w-100"
              id="search-button"
              variant="outlined-light"
              type="submit"
              href={data.url}
              target="_blank"
              rel="noreferrer"
            >Przejdź do oryginalnego dokumentu</Button>
              : null
          }
          <Button
            className="w-100"
            id="search-button"
            variant="contained-dark"
            type="submit"
            href={internalURL}
            target="_blank"
            rel="noreferrer"
          >Wyświetl plik</Button>
        </Stack>
      </CardContent>
    </Card>
  </MDBRow>;
}

export function formatData(data: any) {
  return data.map((d: any) => {
    if (d.source === Source.PL_JUDICIARY) {
      let citation = "";
      if (d.judgment_type === "SENTENCE")
        citation += "Wyrok";
      else if (d.judgment_type === "DECISION")
        citation += "Postanowienie";
      else if (d.judgment_type === "RESOLUTION")
        citation += "Uchwała";
      else if (d.judgment_type === "REASONS")
        citation += "Uzasadnienie";
      else
        citation += "Orzeczenie";

      if (d.code === "SUPREME" || d.id.includes("www.sn.pl")) {
        if (d.division !== "")
          citation += ` Sądu Najwyższego (${d.division})`;
        else
          citation += ` Sądu Najwyższego`;
      }
      else if ((d.code === "COMMON_COURT" || d.code === "COMMON") && d.court !== undefined) {
        if (d.court == "COMMON_COURT")
          citation += ` Sądu Powszechnego`;
        else {
          let name = d.court.replace("Sąd Okręgowy", "Sądu Okręgowego");
          name = name.replace("Sąd Rejonowy", "Sądu Rejonowego");
          name = name.replace("Sąd Apelacyjny", "Sądu Apelacyjnego");

          citation += ` ${name} (${d.division})`;
        }
      }
      else if (d.code === "CONSTITUTIONAL_TRIBUNAL")
        citation += " Trybunału Konstytucyjnego";
      else if (d.code === "NATIONAL_APPEAL_CHAMBER")
        citation += ` Krajowej Izby Odwoławczej`;
      else if (d.code === "NSA")
        citation += ` Naczelnego Sądu Administracyjnego`;
      else if (d.code === "WSA") {
        let name = d.court.replace("Wojewódzki Sąd Administracyjny", "Wojewódzkiego Sądu Administracyjnego");
        name = name.replace("Sąd Administracyjny", "Sądu Administracyjnego");
        name = name.replace("Sąd Apelacyjny", "Sądu Apelacyjnego");

        if (d.division === d.court)
          citation += ` ${name}`;
        else
          citation += ` ${name} (${d.division})`;
      }

      citation += ` z dnia ${d.judgement_date}`;
      citation += `, ${d.case_number}`

      // timestamp to date
      let date;
      if (String(d.judgement_date).includes("-"))
        date = d.judgement_date;
      else {
        date = (new Date(d.judgement_date * 1000))
        // format to %YYYY-%MM-%DD
        date = date.toISOString().split('T')[0];
      }

      return {
        id: d.case_number,
        displayId: d.case_number,
        date: date,
        citation: citation,
        quote: d.text,
        sourceText: "Orzecznictwo",
        internalUrl: `/show-document?source=${Source.PL_JUDICIARY}&docId=` + d.case_number,
        ...d
      }
    }
    if (d.source === "pl_codexes")
      return {
        id: d.full_name,
        displayId: d.full_name,
        date: d.promulgation,
        citation: d.full_name,
        quote: d.text,
        sourceText: "Kodeksy",
        ...d
      }
    if (d.source === Source.PL_EUREKA) {
      // timestamp to date
      let date;
      if (String(d.judgement_date).includes("-"))
        date = d.judgement_date;
      else {
        date = (new Date(d.judgement_date * 1000))
        // format to %YYYY-%MM-%DD
        date = date.toISOString().split('T')[0];
      }

      return {
        id: d.id || d.case_number,
        displayId: d.case_number,
        date: date,
        court: d.court,
        quote: d.text,
        citation: `Interpretacja podatkowa z dnia ${date} numer ${d.case_number}`,
        sourceText: "Interpretacje podatkowe",
        internalUrl: `/show-document?source=${Source.PL_EUREKA}&docId=` + d.case_number,
        ...d,
      }
    }
    if (d.source === Source.PL_OWN_DATABASE)
      return {
        ...d,
        id: d.path,
        displayId: d.id,
        date: d.date,
        court: d.court,
        quote: d.text,
        citation: `Dokument wewnętrzny: ${d.path.split("/").pop()}`,
        sourceText: "Baza własna",
        internalUrl: "/show-document?path=" + d.path,
      }
    if (d.source === Source.PL_EURLEX) {
      return {
        id: d.id,
        displayId: d.id,
        date: d.judgement_date,
        court: d.court,
        quote: d.text.replace("|-|-|-|-|-|-|", ""),
        citation: d.title,
        sourceText: "Prawo Europejskie",
        internalUrl: `/show-document?source=${Source.PL_EURLEX}&docId=` + d.id,
        ...d
      }
    }
    if (d.source === Source.PL_ACTS) {
      return {
        id: d.ELI,
        displayId: d.ELI,
        date: d.announcementDate,
        court: d.address,
        citation: d.title,
        quote: d.text.replace("|-|-|-|-|-|-|", ""),
        sourceText: "Akty prawne",
        internalUrl: `/show-document?source=${Source.PL_ACTS}&docId=` + d.ELI,
        ...d
      }
    }

    return d
  })
}