import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import "./DataSources.scss";

const DataSources = () => {
    return (<>
        <div className="page-sources">
            <h4>Dane Gaius-Lex - jak to działa?</h4>
            <div className="introduction">
                W Gaius-Lex przykładamy ogromną wagę do źródeł z których dostarczamy dane naszym klientom. Wszystkie dane, których używamy są dostępne publicznie na stronach rządowych. Dbamy o to aby na bieżąco aktualizować obecne i dodawać nowe źródła danych, tak aby nasze narzędzia mogły być dla Was jeszcze bardziej użyteczne.
            </div>
            </div>
            <div className="data-sources">
                <MDBRow>
                    <MDBCol>
                        <Card className="ds-card">
                            <CardContent>
                                <Typography className="card-title">Orzeczenia Sądowe</Typography>
                                <Typography>
                                <a href="https://www.saos.org.pl/">saos.org.pl</a><br />
                                <a href="https://orzeczenia.nsa.gov.pl/">orzeczenia.nsa.gov.pl</a><br />
                                <a href="https://orzeczenia.ms.gov.pl/">orzeczenia.ms.gov.pl</a><br />
                                <a href="https://www.sn.pl/wyszukiwanie/SitePages/orzeczenia.aspx">www.sn.pl</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </MDBCol>
                    <MDBCol>
                        <Card className="ds-card">
                            <CardContent>
                                <Typography className="card-title">Kodeksy i ustawy</Typography>
                                <Typography>
                                    <a href="https://isap.sejm.gov.pl/">isap.sejm.gov.pl</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </MDBCol>
                    <MDBCol>
                        <Card className="ds-card">
                            <CardContent>
                                <Typography className="card-title">Interpretacje podatkowe</Typography>
                                <Typography>
                                    <a href="https://eureka.mf.gov.pl/">eureka.mf.gov.pl</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </MDBCol>
                </MDBRow>
                <MDBRow id="info-row">
                    <Card className="ds-card-contact">
                        <CardContent>
                            <Typography>Znasz źródło danych, które chciałbyś zobaczyć w Gaius Lex? Pomóż nam rozwijać Gaiusa w nowych obszarach!</Typography>
                            <Typography>
                                Skontaktuj się z nami i zaproponuj dodanie nowego źródła!
                            </Typography>
                        </CardContent>
                    </Card>
                </MDBRow>
            </div>
    </>);
}

export default DataSources;