import React from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { Box, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import "./index.scss";
import Button from "../../components/generic/Button";
import getCSRFToken from "../../stores/CSRFStore";
import Colors from "../../components/colors";

const columns = [
  { field: "query", headerName: "Pytanie", align: "left", flex: 1 },
  { field: "sources", headerName: "Typ", width: 170 },
  {
    field: "added",
    headerName: "Data zapytania",
    width: 170,
    disableReorder: true,
    sortable: false,
    valueFormatter: (date: { value: string }) => new Date(date.value).toLocaleString()
  },
];

const getSource = (sources: string | string[]) => {
  const source = sources === "judiciary" ? "Orzecznictwo" : sources;
  return source;
};

const getRows = (queryRows: any[], chatLogRows: any[]) => {
  const rowsArray = queryRows.map((row) => {
    return {
      key: row.key,
      query: row.query,
      sources: getSource(row.sources),
      added: row.added,
    };
  });

  const rowsObject = Object.entries(chatLogRows).map(([key, value]) => {
    return ({
    key: key,
    query: value.messages[0].text,
    sources: "chat",
    added: value.added
  })
  });

  const combinedArray = [...rowsArray, ...rowsObject];
  const sortedArray = combinedArray.sort((a, b) => {
    return new Date(b.added).getTime() - new Date(a.added).getTime();
  }).map((item, index) => ({
    ...item,
    id: index + 1
  }));

  return sortedArray;
};

const HistoricalQueries = ({ initPageSize, initPageSizeOptions }: { initPageSize?: number, initPageSizeOptions?: number[] }) => {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [select, setSelect] = React.useState<string>();
  const [startingFrom, setStartingFrom] = React.useState<string>();
  const snackBarContext = React.useContext(SnackbarContext);
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const [historicalQueries, setHistoricalQueries] = React.useState<any[]>([]);
  const [historicalChatLogs, setHistoricalChatLog] = React.useState<any>({});
  const [selected, setSelected] = React.useState<any>("");

  const refresh = async () => {
    setSelected("");
    setSelectionModel([]);
    setLoading(true);
    await refreshQueries();
    await refreshChatLogs();
    setLoading(false);
  }

  const refreshQueries = async () => {
    const url = select
      ? `api/v1/share-results?key=ALL&starting_from=${startingFrom}`
      : "api/v1/share-results?key=ALL";

    return axios
      .get(url)
      .then((response) => {
        setHistoricalQueries(response.data.data.reverse());
      })
      .catch((error) => {
        snackBarContext.setMessage(
          "Wystąpił błąd podczas pobierania historii zapytań"
        );
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
      });
  }

  const refreshChatLogs = async () => {
    const url = select
      ? `api/v1/chat-history?starting_from=${startingFrom}`
      : "api/v1/chat-history";

    return axios.get(url)
        .then((response) => {
          const data = response.data.result;
          setHistoricalChatLog(data);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  React.useEffect(() => {
    refresh();
  }, [select]);

  const getSelectorValue = (variant: string): string | undefined => {
    const today = new Date();
    if (variant === "1") return today.toISOString().split("T")[0];
    else if (variant === "2") {
      const dayOfTheWeek = today.getDay() - 1;
      const timeToday = today.getTime();
      const firstDayOfTheWeek = timeToday - dayOfTheWeek * 24 * 60 * 60 * 1000;
      return new Date(firstDayOfTheWeek).toISOString().split("T")[0];
    } else if (variant === "3")
      return today.toISOString().split("T")[0].slice(0, -2) + "01";
    else return undefined;
  };

  const removeRow = async (key: string, sources: string) => {
    await getCSRFToken();
    const url = sources === "chat" ? "/api/v1/chat-history" : "/api/v1/share-results"

    await axios
      .delete(`${url}?key=${key}`)
      .then(() => {
        snackBarContext.setMessage("Usunięto element z historii zapytań");
        snackBarContext.setSeverity("success");
        snackBarContext.setOpen(true);
      })
      .catch((error) => {
        console.log(error);
        snackBarContext.setMessage(
          "Wystąpił błąd podczas usuwania historii zapytań"
        );
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
      });
    refresh();
  };

  return (
    <div id="historicalQueries">
      <Stack direction={"row"} justifyContent={"space-between"}>
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: 12
        }}>
          <h2 className="heading__h2" style={{ margin: 0 }}>Historia zapytań</h2>
          <Select
            sx={{ height: 35, minWidth: 120, borderRadius: "0.75rem" }}
            onChange={(event: SelectChangeEvent) => {
              setSelect(event.target.value);
              setStartingFrom(getSelectorValue(event.target.value));
            }}
          >
            <MenuItem value={""}>Wszystkie</MenuItem>
            <MenuItem value={"1"}>Dzisiaj</MenuItem>
            <MenuItem value={"2"}>Ten tydzień</MenuItem>
            <MenuItem value={"3"}>Ten miesiąc</MenuItem>
          </Select>
        </div>
        <div>
          <Button
            variant="outlined-light"
            onClick={() => {
              if (selected.key) { 
                if (selected.sources == "chat") { 
                  navigate("/chat", { state: { logId: selected.key } })
                }
                else {
                  navigate(`/show-results?key=${selected.key}`);
                }
              }
            }}
            disabled={!selected}
            sx={{
              width: "fit-content",
              marginX: "0.5rem",
            }}
          >
            Przejdź
          </Button>
          <Button
            variant="contained-dark"
            color={Colors.Red}
            onClick={() => {
              if (selected.key) removeRow(selected.key, selected.sources);
            }}
            disabled={!selected}
          >
            Usuń
          </Button>
        </div>
      </Stack>
      <Stack direction={"column"} spacing={2}>
        <DataGrid
          loading={loading}
          columns={columns as GridColDef[]}
          localeText={{ noRowsLabel: "Brak historii zapytań" }}
          rows={getRows(historicalQueries, historicalChatLogs)}
          rowSelectionModel={selectionModel}
          onRowClick={({ row }) => {
            setSelected(row);
            setSelectionModel([row.id]);
          }}
          pageSizeOptions={initPageSizeOptions || [10, 15, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: initPageSize || 15 } },
          }}
          hideFooterSelectedRowCount
        />
      </Stack>
    </div>
  );
};

export default HistoricalQueries;
