import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import Button from "../../components/generic/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik, ErrorMessage } from "formik";
import { useNavigate, useLocation, Link, useSearchParams } from "react-router-dom";
import axios from "../../api/axios";
import getCSRFToken from "../../stores/CSRFStore";
import * as Yup from "yup";
import { Helmet } from "react-helmet";

// import { buttonStyleSX } from "~/src/components/styles";
import { LoginButtonStyleSX } from "./style";
// import mautic from "mautic-tracking";
// import useTracker from "~/src/hooks/useTracker";

import React from "react";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";
// import useConfig from "~/src/hooks/useConfig";
import GoogleButton from "../../components/GoogleButton";
import TextStrings from "./Strings";
import FacebookButton from "../../components/FacebookButton";
import Background from "../../components/generic/Background";
import { Colors } from "../../components/generic/Colors";
import "./index.scss";
// @ts-ignore
import Cookies from "js-cookie";
import { SnackbarContext } from "../../contexts/SnackbarContext";

const ErrorStyle = { color: "red", backgroundColor: Colors.LightRed, height: 50, display: "flex", alignItems: "center", margin: "5px 0", padding: "0 10px" };

function LoginView(props) {
  const returnCustomer = Cookies.get("returnCustomer");
  const [searchParams, _] = useSearchParams();
  const socialLogin = searchParams.get('socialLogin') || '';
  const [disabled, setDisabled] = React.useState(!returnCustomer);
  // const tracker = useTracker();
  const navigate = useNavigate();
  const location = useLocation();
  // const config = useConfig();
  const from =
    location.state?.from?.pathname ||
    `/`;

  const [fpHash, setFpHash] = React.useState(undefined);

  // Set wider margins for the page
  // React.useEffect(() => {
  //   document
  //     .getElementById("main-container")
  //     .classList.replace("container", "container-fluid");

  //   return function cleanup() {
  //     try {
  //       document
  //         .getElementById("main-container")
  //         .classList.replace("container-fluid", "container");
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  // }, [window.innerWidth]);

  // Set fingerprint
  // React.useEffect(() => {
  //   const setFp = async () => {
  //     const fp = await FingerprintJS.load();
  //
  //     const { visitorId } = await fp.get();
  //     console.log("Setting fphash");
  //     setFpHash(visitorId);
  //   };
  //
  //   setFp();
  // }, []);

  const handleSubmit = async (values, actions) => {
    const { email, password, fingerprint } = values;

    try {
      await axios.post(
        "/rest-auth/login/",
        JSON.stringify({ email, password, fingerprint }),
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // mautic.pageView({
      //   url: "/login",
      //   email,
      // });
      // tracker.sendEvent("login", { method: "email" });

      Cookies.set("returnCustomer", "true", { expires: 30 });

      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        actions.setErrors({
          email: TextStrings.errors.internet,
        });
      } else if (err?.response?.status === 400) {
        axios.post('/core_api/user/exist', {email: email}).then((res) => {
          if(!res.data.exists) {
            window.location.href = '/register?email=' + email;
          }
        });
        actions.setErrors({
          email: TextStrings.errors.wrongCredentials,
        });
      } else if (err?.response?.status === 401) {
        actions.setErrors({
          email: TextStrings.errors.wrongCredentials,
        });
      } else {
        actions.setErrors({ email: TextStrings.errors.unknown });
      }
    }
  };
  getCSRFToken();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handleEmailInput = async (e) => {
    if (e && e.persist) {
      e.persist();
    }
    if (!emailRegex.test(e.target.value)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  return (
    <Background>
      <Helmet>
        <title>{TextStrings.loginPageTitle}</title>
        <meta name="description" content={TextStrings.meta} />
        <link
          rel="canonical"
          href={
            process.env.MAIN_ENDPOINT +
            process.env.PREFIX +
            `/login`
          }
        />
      </Helmet>
        <MDBRow id={"form-row"} className="flex-grow-1 w-100 m-0">
        <MDBCol className="d-flex flex-column col-sm-12 col-s-12 col-12 px-0">
          <MDBCard
            id="login-form-card"
            className="my-auto mx-auto rounded-6"
            style={{
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <MDBCardBody>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  fingerprint: fpHash,
                }}

                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email(TextStrings.errors.emailInvalid)
                    .required(TextStrings.errors.emailRequired),
                  password: Yup.string()
                    .required(TextStrings.errors.passwordRequired)
                    .min(8, TextStrings.errors.passwordTooShort),
                  fingerprint: Yup.string(),
                })}
              >
                {(formik) => {
                  return (
                    <Form id="login-form" data-rewardful="true">
                      <h2 className="pb-4 h2-header">
                        {socialLogin
                          ? TextStrings.socialLogin.title
                          : TextStrings.welcome}
                      </h2>
                      {socialLogin && (
                        <p>{TextStrings.socialLogin.description}</p>
                      )}
                      {/* error message */}
                      <div id="error-messages" className="text-center">
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="cx-error-message-email error-msg rounded-6"
                          style={ErrorStyle}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="cx-error-message-password error-msg rounded-6"
                          style={ErrorStyle}
                        />
                      </div>
                      <div id="login-input" className="grey-text">
                        <MDBInput
                          id="email-input"
                          name="email"
                          label={TextStrings.emailInput}
                          icon="envelope"
                          type="email"
                          value={formik.values.email}
                          autoComplete={"email"}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleEmailInput(e);
                          }}/>
                        <MDBInput
                          className="mt-2"
                          id="password-input"
                          name="password"
                          label={TextStrings.passwordInput}
                          icon="lock"
                          type="password"
                          autoComplete={"current-password"}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {!socialLogin && (
                        <div
                          id="login-social-buttons"
                          className={"text-center pt-2 pb-2" + (disabled ? " disabled-login" : "")}
                          onClick={disabled ? () => {
                            document.getElementById("email-input").focus();
                          } : undefined}
                        >
                          {disabled && <div style={{width: "100%", height: "100%", position: "absolute", zIndex: 20}}>
                            <div className="disabled-login__tooltip">Aby zalogować się przez Google lub Facebook, najpierw podaj adres e-mail powiązany z&nbsp;kontem.</div>
                          </div>}
                          <GoogleButton email={formik.values.email} />
                          <FacebookButton email={formik.values.email} />
                        </div>
                      )}
                      <div
                        style={{ display: "block", width: "100%" }}
                        className="text-center pt-2 pb-2"
                      >
                        <Button
                          variant="contained-dark"
                          width={"100%"}
                          type="submit"
                          disabled={fpHash === ""}
                        >
                          {fpHash === "" ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            TextStrings.login
                          )}
                        </Button>
                      </div>
                      <div>
                        <Link
                          to={`/register`}
                          className="text-center"
                          style={{ paddingRight: 15, color: Colors.DarkGrey }}
                        >
                          {TextStrings.noAccount}
                        </Link>
                        <Link
                          to={process.env.PREFIX + `/password-reset`}
                          className="text-center"
                          style={{ padding: "0 15px", color: Colors.DarkGrey }}
                        >
                          {TextStrings.forgotPassword}
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Background>
  );
}

export default LoginView;
