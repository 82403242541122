import React from "react";
import "./Team.scss";
import { Colors } from "../../components/generic/Colors";


const Team = () => {
    const jakubImage = require("~/public/static/img/jakub.jpeg");
    const witekImage = require("~/public/static/img/witek.jpeg");
    const pawelImage = require("~/public/static/img/pawel.jpg");
    const zanetaImage = require("~/public/static/img/zaneta.jpeg");
    const janekImage = require("~/public/static/img/janek.jpeg");

    return (<>
        <h1 style={{color: Colors.DarkNavyBlue, fontSize: 24}} >Zespół Gaius Lex</h1>
        <div className="page">
            {/* <div className="team-member-section">
                <div className="tms-content">
                    <div className="tms-data">
                            <img className="tms-img" src={jakubImage}></img>
                            <h2 className="tms-name">Jakub Wolert</h2>
                            <h3 className="tms-role">Co-Founder, zarządzanie sprzedażą</h3>
                            <p>
                                Cześć, w naszym projekcie zajmuje się szerokim spektrum działań takich jak, pozyskiwanie funduszy, efektywna współpraca z działem realizacji, wdrażanie zmian, pozyskiwanie klientów, zarządzanie marketingiem oraz dbanie o satysfakcje klienta.
                            </p>
                            <p>
                                Jestem dumny z udziału Gaius-Lex. Wierzę, że warto pozbyć się monotonnej, powtarzalnej pracy, aby skupić się na aspektach pracy, które naprawdę mają znaczenie - Relacjach z ludźmi.
                            </p>
                            <p>
                                Absolwentem prestiżowego programu IRSM - Business Development Mastery, kierunku pod okiem Szymona Negacza, jestem praktykiem sprzedaży i marketingu, z pasją do najnowszych technologii oraz branży nieruchomości. Moje wykształcenie obejmuje nie tylko teoretyczne aspekty biznesu, ale także praktyczne umiejętności zdobyte podczas praktyk. Zafascynowany dynamiką rynku, postanowiłem połączyć wiedzę z obszaru nowoczesnych technologii i sprzedaży, co znalazło swoje odzwierciedlenie w prężnie rozwijającej się firmie Gaius-Lex i QuickPick. Moja działalność w QuickPick skupia się na dostarczaniu skutecznych rozwiązań dla firm, pomagając im osiągnąć sukces w marketingu. W pełni zaangażowany w rozwój, nieustannie poszukuję innowacyjnych strategii i technologii, aby sprostać dynamicznym wymaganiom rynku
                            </p>
                            <p>
                                Zapraszam do odkrywania jak wspólnie z zespołem Gaius-Lex pomagamy absolwentom i praktykom prawa w delegowaniu powtarzalnej pracy i skoncentowaniu się na najważniejszych aspektach Twojego biznesu.
                            </p>
                    </div>
                </div>
            </div> */}
            <div className="team-member-section">
                <div className="tms-content">
                    <div className="tms-data">
                        <img className="tms-img" src={pawelImage}></img>
                        <h2 className="tms-name">Paweł Kulig</h2>
                        <h3 className="tms-role">CEO</h3>
                        <p>Cześć, jestem Paweł, specjalista ds. infrastruktury IT w Gaius-Lex. Jestem fanem nowych technologi i tworzenia nowoczesnych i wydajnych rozwiązań.</p>

                        <p>W mojej roli skupiam się na zapewnieniu sprawnego działania infrastruktury IT oraz rozwijaniu platformy Gaius-Lex, wprowadzając nowe funkcjonalności. Dzięki moim umiejętnościom zarządzania projektem, pracujemy nad stale rozwijającym się narzędziem, które pozwala użytkownikom pozbyć się monotonnej pracy i skupić się na tym, co najważniejsze - ludziach.</p>

                        <p>Prywatnie uwielbiam żeglarstwo, snowboard i gry komputerowe oraz wspólny czas z moją żoną i synem. Pochodzę z Krakowa studiowałem na Akademii Górniczo Hutniczej i Uniwersytecie Jagielońskim. Jestem absolwentem Elektroniki i Telekomunikacji oraz dwóch Informatyk.</p>
                    </div>
                </div>
            </div>
            <div className="team-member-section">
                <div className="tms-content">
                    <div className="tms-data">
                            <img className="tms-img-right" src={witekImage}></img>
                            <h2 className="tms-name">Witold Wydmański</h2>
                        <h3 className="tms-role">CTO, specjalista AI</h3>
                            <p>
                                Cześć, jestem Witek. Jestem entuzjastą sztucznej inteligencji i doktorantem w dziedzinie uczenia maszynowego. Moja pasja do technologii i innowacji znalazła idealne miejsce w Gaius-Lex, startupie skupiającym się na rewolucji w obszarze wsparcia adwokatów i osób zajmujących się prawem poprzez efektywną automatyzację powtarzalnej pracy.
                            </p>
                            <p>
                                W Gaius-Lex, dzięki zaawansowanej technologii wyszukiwania dokumentów opartej na sztucznej inteligencji, tworzymy narzędzie, które umożliwia szybkie znalezienie odpowiedzi na pytania prawników. Jako specjalista od algorytmów AI i inżynier danych, moją rolą jest wyposażanie Gaiusa w nowe umiejętności, aby służył naszym użytkownikom jeszcze lepiej.
                            </p>
                            <p>
                                Prywatnie fan nowych technologii (czytaj: gadżeciarz) i gier komputerowych. Pochodzę z Sosnowca, ale od czasów studiów (AGH i UJ) mieszkam w Krakowie. Quentin Dupieux to najlepszy działający obecnie reżyser i nikt nie przekona mnie, że jest inaczej.
                            </p>
                    </div>
                </div>
            </div>

            <div className="team-member-section">
                <div className="tms-content">
                    <div className="tms-data">
                        <div className="tms-text">
                            <img className="tms-img-right" src={zanetaImage}></img>
                            <h2 className="tms-name">Żaneta Poręba</h2>
                            <h3 className="tms-role">Co-Founder, specjalista FullStack</h3>
                            <p>
                                Cześć, Jestem Żaneta, specjalistka Full-stack w zespole Gaius-Lex. Interesuję się tworzeniem oraz rozwijaniem nowoczesnych i zoptymalizowanych rozwiązań systemowych.
                            </p>
                            <p>
                                Moja rola polega na zapewnieniu kompleksowego podejścia do rozwoju naszej platformy, tak aby prawnicy mogli skorzystać z niej w pełni. Na warstwie frontendowej dbam o intuicyjny interfejs, który ułatwia korzystanie z naszego narzędzia. Z kolei na backendzie zajmuję się implementacją rozwiązań, które spełniają specyficzne potrzeby środowiska prawniczego. W mojej pracy stawiam na optymalizację działania platformy, by prawnicy mogli skoncentrować się na istotnych aspektach swojej pracy, a jednocześnie korzystać z zaawansowanej technologii filtrowania i wyszukiwania dokumentów opartej na sztucznej inteligencji.
                            </p>
                            <p>
                                Prywatnie pochodzę z Nowego Sącza oraz ukończyłam studia z Informatyki Stosowanej na Uniwersytecie Jagiellońskim. Moje zainteresowania to m.in. snowboarding, ćwiczenia siłowe oraz niedawno rozpoczęta nauka gry na pianinie.
                            </p>
                            <p>
                                Jeśli chcesz, aby korzystanie z narzędzi technicznych było łatwe i efektywne, Gaius-Lex jest miejscem, które zrozumie i spełni Twoje prawnicze potrzeby. Zapraszam do odkrywania!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="team-member-section">

                <div className="tms-content">
                    <div className="tms-data">
                            <img className="tms-img" src={janekImage}></img>
                            <h2 className="tms-name">Janusz Marszałek</h2>
                            <h3 className="tms-role">Co-Founder, specjalista FullStack</h3>
                            <p>
                                Cześć, jestem Janusz, specjalista Full-stack w Gaius-Lex, a moją mocną stroną jest obszar oprawy graficznej i interakcji z aplikacją. Odpowiadam za stworzenie przyjaznego dla oka i intuicyjnego interfejsu, który ułatwia korzystanie z naszej platformy.
                            </p>
                            <p>
                                Na warstwie frontendowej dbam o estetyczny wygląd oraz funkcjonalności, które sprawiają, że korzystanie z Gaius-Lex jest nie tylko efektywne, ale również przyjemne dla użytkowników. Specjalizuję się w tworzeniu interaktywnych elementów, które ułatwiają nawigację i zapewniają pozytywne doświadczenia użytkownikom.
                            </p>
                            <p>
                                Jednocześnie, jako Full-stack, biorę udział w różnych etapach projektu, zajmując się także implementacją rozwiązań po stronie backendu. Moim celem jest zapewnienie kompleksowej jakości naszej aplikacji, aby prawnicy mogli skupić się na treści i wydajności, mając pewność, że interfejs graficzny spełnia ich oczekiwania.
                            </p>
                            <p>
                                Jeśli zależy Ci na atrakcyjnej i funkcjonalnej oprawie graficznej oraz płynnej interakcji z aplikacją, Gaius-Lex to miejsce, które oferuje zaawansowane technologicznie narzędzia przy jednoczesnym dbaniu o estetykę i użyteczność.
                            </p>
                    </div>

                </div>
            </div>
        </div>
    </>);
}

export default Team;