import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Chip, Grid, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Colors from "../../components/colors";
import { Description, ExpandMoreOutlined } from "@mui/icons-material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import getCSRFToken from "../../stores/CSRFStore";
import { Replay } from "@mui/icons-material";
import { useNavigate } from "react-router";
import useUserData from "../../hooks/useUserData";

const GaiusLexLogo = require("~/public/static/img/gaius-lex-logo.svg");


export interface MessageProps {
  message: string;
  role: string;
  ref?: any;
  type: string;
}

const RedoButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      color="primary"
      size="small"
      className="p-0 m-0"
      sx={{
        padding: "0px",
        margin: "0px",
        minWidth: "32px",
        minHeight: "32px",
      }}
      disableTouchRipple
    >
      <Replay
        fontSize="small"
        color="primary"
      />
    </Button>
  );
}

const QuoteMark = ({ quote, source, quoteNo }: { quote: string, source: string, quoteNo: number }) => {
  // show an upper index with the source of the quote

  return (
    <Tooltip title={`${source}: ${quote}`} placement="top">
      <div style={{
        display: "inline",
        backgroundColor: Colors.getHue(Colors.LightBlue, 90),
        fontWeight: "bold",
        cursor: "pointer",
        verticalAlign: "super",
        padding: "2px",
        paddingLeft: "4px",
        paddingRight: "4px",
        borderRadius: "4px",
        fontSize: "0.6em",
        border: `1px solid ${Colors.getHue(Colors.LightBlue, 70)}`,
      }}
      >
        {quoteNo}
      </div>
    </Tooltip>
  );
}

export const Message = ({ key, message, isLastMessage, redo }: { key?: any, message: MessageProps, isLastMessage?: boolean, redo?: any }) => {
  const axios = useAxiosPrivate();
  const snackBarContext = React.useContext(SnackbarContext);
  const { userData } = useUserData();

  const msgFormatted = message.message.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/\*{2}((?=[^\s\*]).*?[^\s\*])\*{2}/g, "<strong>$1</strong>");

  // replace all <quote source='src'> tags with a QuoteMark component
  // the quote tag is in the form <quote source='src'>quote</quote>
  const { role, type, ref } = message;

  const downloadAsDocx = async () => {
    const content = message.message.split("```")[1];

    await getCSRFToken();
    axios.post("api/v1/download", JSON.stringify({
      "content": content,
    }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => {
        const b64 = response.data;
        // Bytes to base64
        const link = document.createElement("a");
        link.href = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + b64;
        link.setAttribute("download", "answer.docx");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error(error);
        snackBarContext.setMessage("Wystąpił błąd podczas pobierania dokumentu");
        snackBarContext.setSeverity("error");
        snackBarContext.setOpen(true);
      });
  };

  let downloadBtn = null;
  if (type === "document") {
    downloadBtn = (
      <div className={"chat__message-attachments"}>
        <Button variant="outlined" color="primary" size="small" className="px-4 py-3" onClick={downloadAsDocx}>
          <Description />
          Pobierz DOCX
        </Button>
      </div>
    );
  }

  if (role === "gaius")
    return (
      <div className={"chat__message chat__message-gaius"}>
        <div className="chat__message__avatar">
          <img src={GaiusLexLogo} alt="gaius-lex logo" />
        </div>
        <div style={{ flex: 1 }}>
          <div className={"chat__message__content"}
            ref={ref}>
            <p dangerouslySetInnerHTML={{ __html: msgFormatted }} />
          </div>
          {downloadBtn}
        </div>
        {isLastMessage && (<RedoButton onClick={redo} />)}
      </div>
    );

  if (role === "search") {
    const msgClean = msgFormatted.split("```")[1];

    const quoteRegex = /<quote source=(?:"|')(.*?)(?:"|')>([^\<]*?)<\/quote>/g;
    const splitText = msgClean.split(quoteRegex);
    const quoteElements = [];

    let foundQuotes = 1;

    for (let i = 0; i < splitText.length; i++) {
      if (i % 3 === 0) {
        quoteElements.push(splitText[i]);
      } else if (i % 3 === 1) {
        quoteElements.push(<QuoteMark source={splitText[i]} quote={splitText[i + 1]} quoteNo={foundQuotes} />);
        foundQuotes++;
      }
    }

    return (
      <>
        <Accordion defaultExpanded key={key} className="shadow-0" sx={{
          backgroundColor: Colors.getHue(Colors.Orange, 90),
          borderRadius: "10px",
          border: "none",
          "::before": {
            content: "none",
          }
        }}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreOutlined />
            }
          >
            <Typography variant="h6" color="text.primary" className="m-1">
              Wyniki wyszukiwania
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack key={key} sx={{
              marginY: "10px",
            }}>
              <Box
                ref={ref}
                sx={{
                  display: 'flex',
                  alignItems: 'left',
                  textAlign: "justify",
                  padding: "10px",
                  width: "fit-content",
                  maxWidth: "100%",
                  wordWrap: "anywhere"
                }}>
                <Typography variant="body1"
                  color="text.primary" className="m-1"
                  style={{
                    overflowWrap: "anywhere"
                  }}
                >
                  {
                    quoteElements.map((element, index) => {
                      if (typeof element === "string") {
                        return <span key={index} dangerouslySetInnerHTML={{ __html: element }} />;
                      }
                      return element;
                    })
                  }
                </Typography>
              </Box>
              {downloadBtn}
            </Stack>
          </AccordionDetails>
        </Accordion>
        {
          userData.featureFlags.includes("doc_editor") &&
          <GoToEditorButton text={message.message.split("```")[1]} />
        }
      </>
    );
  }

  return (
    <div className={"chat__message chat__message-user"}>

      <div className={"chat__message__content"}
        ref={ref}>
        <p dangerouslySetInnerHTML={{ __html: msgFormatted }} />
      </div>
      <div className="chat__message__avatar">

      </div>
    </div>
  );
};

const GoToEditorButton = ({ text }: { text: string }) => {
  const navigate = useNavigate();

  return (
    <Box className="d-flex align-items-end flex-column">
      <Button variant="outlined" color="primary" size="small" className="px-4 py-3"
        sx={{
          width: "fit-content",
          justifySelf: "end",
        }}
        onClick={() => navigate("/doc-editor", { state: { initialText: text, title: "raport.md" } })}>
        Przejdź do walidacji
      </Button>
    </Box>
  );
};
