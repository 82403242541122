import React from "react";
import Background from "../../components/generic/Background";
import { MDBIcon, MDBRow } from "mdb-react-ui-kit";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import getCSRFToken from "../../stores/CSRFStore";
import SecretAnswersForm from "../../components/secretAnswersForm";
import "./index.scss";
import { Box, Modal, Typography } from "@mui/material";
import Button from "../../components/generic/Button";

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: "none",
  borderRadius: "0.75rem",
  boxShadow: 24,
  p: 4,
};

const SecretAnswersModal = () => {
  const [modalOpen, setModalOpen] = React.useState(true);

  return (
    <Modal id="secret-answer-modal" open={modalOpen}>
        <Box sx={MODAL_STYLE} >
          <Typography sx={{ mt: 2, display: "inline-flex" }} color={"black"} >
          <MDBIcon fas icon="lock" id="lock-icon" />
          <Typography>
          Od teraz wszystkie nowe dokumenty będą zaszyfrowane za pomocą Twojego hasła użytkownika. W przypadku zgubienia hasła i konieczności jego resetu, konieczne będzie podanie odpowiedzi na wcześniej ustalone pytania bezpieczeństwa.
          <br /><br />
          Po zamknięciu tego komunikatu przejdziesz do procedury ustalania pytań bezpieczeństwa.
          <br /><br />
          Dbaj o swoje hasło i pamiętaj, że bez niego dostęp do zaszyfrowanych dokumentów będzie niemożliwy.
          </Typography>
          </Typography>
          <Typography align='right' margin={2} >
            <Button variant={"contained-dark"} width={"100%"} onClick={() => {
                setModalOpen(false);
            }} >OK</Button>
          </Typography>
        </Box>
      </Modal>
  )
}

const SecretAnswers = () => {
  const axiosPrivate = useAxiosPrivate();
  const snackbar = React.useContext(SnackbarContext);

  const onSubmit = async (values: {
    secretAnswer1: string;
    secretAnswer2: string;
    secretAnswer3: string;
  }) => {
    await getCSRFToken();

    axiosPrivate
      .post(
        `/api/v1/generate-encryption-keys`,
        JSON.stringify({
          secretAnswer1: values.secretAnswer1,
          secretAnswer2: values.secretAnswer2,
          secretAnswer3: values.secretAnswer3,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.location.href = "/";
        return response.data;
      })
      .catch((error) => {
        if (error.response.status == 400) {
          (snackbar as any).setMessage(error.response.data.error);
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        } else {
          (snackbar as any).setMessage(
            "Wystąpił błąd podczas wysyłania odpowiedzi, skontaktuj się z nami lub spróbuj ponownie później"
          );
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        }
        console.log(error);
      });
  };

  const onSkip = async () => {
    await getCSRFToken();

    axiosPrivate
      .post(
        `${process.env.BACKEND_ENDPOINT}/core_api/disable-encryption`,
        JSON.stringify({
          encryptionDisabled: true,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.location.href = "/";
        return response.data;
      })
      .catch((error) => {
        if (error.response.status == 400) {
          (snackbar as any).setMessage(error.response.data.error);
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        } else {
          (snackbar as any).setMessage(
            "Wystąpił błąd podczas pomijania, spróbuj jeszcze raz"
          );
          (snackbar as any).setSeverity("error");
          (snackbar as any).setOpen(true);
        }
        console.log(error);
      });
  };

  return (
    <Background>
      <MDBRow
        style={{
          justifyContent: "center",
          marginBottom: "50px",
          height: "70vh",
        }}
      >
        <div id="secret-answers-container">
          <h3>Dbamy o bezpieczeństwo twoich danych</h3>
          <p>
            W przypadku zapomnienia hasła poprosimy Cię o odpowiedź na
            następujące pytania:
          </p>
          <SecretAnswersForm onSubmit={onSubmit} />
          <a id="skip-link" onClick={onSkip}>Pomiń</a>
        </div>
      </MDBRow>
      <SecretAnswersModal />
    </Background>
  );
};

export default SecretAnswers;
